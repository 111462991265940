<div class="navbar">
    <div class="navbar-container mat-body">
        <a class="logo-container" routerLink="/">
            <img class="logo" src="./assets/IconLogo-Grey.svg">
            <img class="letters" src="./assets/LogoFont-Grey.svg">
        </a>
        <div class="d-none d-md-block d-lg-block d-xl-block">
            <div class="navbar-menu">
                <a style="color: #5f5c5c;" class="line" (click)="filterProps('Venta')">Ventas</a>
                <a style="color: #5f5c5c;" class="line" (click)="filterProps('Renta')">Rentas</a>
                <a style="color: #5f5c5c;" class="line" (click)="filterProps('Terreno')">Proyectos</a>
            </div>
        </div>
        
        <div class="navbar-menu navbar-menu-small d-block d-sm-block d-md-none">
            <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon class="material-icons-round" style="color: #5f5c5c;">menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="filterProps('Terreno')">Proyectos</button>
                <button mat-menu-item (click)="filterProps('Renta')">Rentas</button>
                <button mat-menu-item (click)="filterProps('Venta')">Ventas</button>
            </mat-menu>
        </div>
    </div>
    <!-- <div id="grad1"></div>-->
</div>
