import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Filter, SingleFilter } from './filter.model';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    filters: Filter[] = [];
    filters$: BehaviorSubject<Filter[]> = new BehaviorSubject(this.filters);

    constructor() { }

    addFilter(filter: SingleFilter) {
        if(this.filters.length === 0){
            this.filters.push({ attributeName: filter.attributeName, attributeValues: [filter.attributeValue] });
        }else{
            var filterChanged = false;
            this.filters.map((item:Filter) => {
                if(item.attributeName === filter.attributeName){
                    item.attributeValues.push(filter.attributeValue);
                    filterChanged = true;
                }
            });
            if(!filterChanged){
                this.filters.push({ attributeName: filter.attributeName, attributeValues: [filter.attributeValue] })
            }
        }
    }

    update(){
        this.filters$.next(this.filters);
    }

    getFilter(){
        return this.filters;
    }

    clearFilter() {
        this.filters = [];
    }

    toggleFilter(filter:SingleFilter){
        var filterChanged = false;
        this.filters.map((item:Filter) => {
            if(item.attributeName === filter.attributeName){
                //filter exist
                if(item.attributeValues.some(value => value === filter.attributeValue)){
                    if(item.attributeValues.length > 1){
                        item.attributeValues = item.attributeValues.filter(value => value !== filter.attributeValue);
                    }
                    else{
                        this.filters = this.filters.filter((item2: Filter) => item2.attributeName !== filter.attributeName);
                    }
                }
                else //filter doesnt exist
                {
                    item.attributeValues.push(filter.attributeValue);
                }
                filterChanged = true;
            }
        });
        if(!filterChanged){
            this.filters.push({ attributeName: filter.attributeName, attributeValues: [filter.attributeValue] })
        }
    }

    changeFilterValue(filter:SingleFilter){
        var filterChanged = false;
        this.filters.map((item:Filter) => {
            //filter exist
            if(item.attributeName === filter.attributeName){
                item.attributeValues = [filter.attributeValue];
                filterChanged = true;
            }
        });
        if(!filterChanged){
            this.filters.push({ attributeName: filter.attributeName, attributeValues: [filter.attributeValue] })
        }
    }

    clearSingleFilter(attributeName: string){
        this.filters = this.filters.filter((item2: Filter) => item2.attributeName !== attributeName);
    }
}