export interface Filter{
    attributeName: string;
    attributeValues: string[];
}

export class SingleFilter{
    attributeName: string;
    attributeValue: string;
}

export class FilterViewModel {
    propertyType_Casa: boolean = false;
    propertyType_Departamento: boolean = false;
    propertyType_BodegaComercial: boolean = false;
    propertyType_Edificio: boolean = false;
    propertyType_LocalComercial: boolean = false;
    propertyType_LocalenCentroComercial: boolean = false;
    propertyType_Oficina: boolean = false;
    propertyType_Terreno: boolean = false;
    propertyType_TerrenoComercial: boolean = false;
    selectedBedroom: string = "";
    selectedBathroom: string = "";
    transactionType_Renta: boolean = false;
    transactionType_Venta: boolean = false;
    maximum: number = 0;
    minimum: number = 0;
}