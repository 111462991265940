import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Attributes, Property } from '../Shared/Property.model';
import { PropertyService } from '../Shared/property.service';
import { Filter, FilterViewModel, SingleFilter } from './filter.model';
import { FilterService } from './filter.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FilterPopupComponent } from '../filter-popup/filter-popup.component';
import { Subscription } from 'rxjs';
import { trigger, transition, style, animate, query, stagger } from '@angular/animations';

const TREE_DATA: Filter[] = [
  {
    attributeName: 'transactionType',
    attributeValues: ['Renta', 'Venta']
  }, 
  {
    attributeName: 'propertyType',
    attributeValues: [
      'Casa',
      'Departamento',
      //'Bodega Comercial',
      'Edificio',
      'Local Comercial',
      //'Local en Centro Comercial',
      'Oficina',
      'Terreno',
      //'Terreno Comercial'
    ]
  },
  {
    attributeName: 'city',
    attributeValues: ['Mazatlan, Sinaloa']
  },
  {
    attributeName: 'bedrooms',
    attributeValues: ['1+', '2+', '3+', '4+', '5+']
  },
  {
    attributeName: 'bathroomsComplete',
    attributeValues: ['1+', '2+', '3+', '4+', '5+']
  }
];

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
  ),
  transition(':leave',
    [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
  )
]);

const listAnimation = trigger('listAnimation', [
  transition('* <=> *', [
    query(':enter',
      [style({ opacity: 0 }), stagger('60ms', animate('600ms ease-out', style({ opacity: 1 })))],
      { optional: true }
    ),
    query(':leave',
      animate('200ms', style({ opacity: 0 })),
      { optional: true }
    )
  ])
]);

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  animations: [fadeAnimation, listAnimation]
})
export class FilterComponent implements OnInit, OnDestroy {

  transactionExpanded = true;
  propertyTypeExpanded = true;
  priceExpanded = true;
  bedroomsExpanded = true;
  bathroomsExpanded = true;

  filters: Filter[];
  chipFilters: string[];
  attributes = new Attributes();

  filteredProperties: Property[];

  maximum: number;
  minimum: number;
  maxPriceFormControl: FormControl;
  minPriceFormControl: FormControl;
  filtersForm: FormGroup;

  transactionType_Renta: boolean;
  transactionType_Venta: boolean;

  propertyType_Casa: boolean;
  propertyType_Departamento: boolean;
  propertyType_BodegaComercial: boolean;
  propertyType_Edificio: boolean;
  propertyType_LocalComercial: boolean;
  propertyType_LocalenCentroComercial: boolean;
  propertyType_Oficina: boolean;
  propertyType_Terreno: boolean;
  propertyType_TerrenoComercial: boolean;

  filtersSubscription: Subscription;

  selectedBedroom: string;
  bedrooms: string[] = ['1+ Recámaras', '2+ Recámaras', '3+ Recámaras', '4+ Recámaras', '5+ Recámaras'];

  selectedBathroom: string;
  bathrooms: string[] = ['1+ Baños', '2+ Baños', '3+ Baños', '4+ Baños', '5+ Baños'];

  constructor(private filterService: FilterService,
    private propertyService: PropertyService,
    public dialog: MatDialog) { }

  ngOnInit() {
    this.initializeFilters();
    this.filters = this.filterService.getFilter();

    this.filtersSubscription = this.filterService.filters$.subscribe((globalFilters) => {
      this.initializeFilters();
      this.filters = globalFilters;
      this.filters.forEach((filter: Filter) => {
        var filterValues = filter.attributeValues;
        filterValues.map((value: String) => {
          this.chipFilters.push(this.attributes[filter.attributeName] + ": " + value);
          var tempValue = value.replace(" ", "");
          while (tempValue.includes(" ")) {
            tempValue = tempValue.replace(" ", "");
          }
          if(filter.attributeName === "propertyType" || filter.attributeName === "transactionType"){
            this[filter.attributeName + "_" + tempValue] = true;
          }
          if(filter.attributeName === "Bedrooms"){
            this.selectedBedroom = value + "+ Recámaras";
          }
          if(filter.attributeName === "Bathrooms"){
            this.selectedBedroom = value + "+ Baños";
          }
          if(filter.attributeName === "minimum"){
            this.minimum = Number(value);
          }
          if(filter.attributeName === "maximum"){
            this.maximum = Number(value);
          }
          this.applyFilters();
        });
      });
      if (this.minimum != 0)
        this.minPriceFormControl = new FormControl(this.minimum, Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));
      else
        this.minPriceFormControl = new FormControl('', Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));

      if (this.maximum != 0)
        this.maxPriceFormControl = new FormControl(this.maximum, Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));
      else
        this.maxPriceFormControl = new FormControl('', Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));
    });
  }

  remove(filter: string): void {
    const value = filter.split(":")[1].trim();
    var temp: SingleFilter = new SingleFilter();

    var deleted = TREE_DATA.some((filter2: Filter) => {
      if (filter2.attributeValues.some(item => item === value)) {
        temp.attributeName = filter2.attributeName;
        temp.attributeValue = value;
        var tempValue = value.replace(" ", "");
        while (tempValue.includes(" ")) {
          tempValue = tempValue.replace(" ", "");
        }
        if(filter2.attributeName === "propertyType" || filter2.attributeName === "transactionType"){
          this[filter2.attributeName + "_" + tempValue] = false;
        }
        return true;
      }
    });

    if(!deleted){
      const name = filter.split(":")[0].trim();
      if(name === "Baños"){
        temp.attributeName = "Bathrooms";
        this.selectedBathroom = "";
      }
      if(name === "Recámaras"){
        temp.attributeName = "Bedrooms";
        this.selectedBedroom = "";
      }
      if(name === "Precio Mínimo"){
        temp.attributeName = "minimum";
        this.minPriceFormControl.reset();
      }
      if(name === "Precio Máximo"){
        temp.attributeName = "maximum";
        this.maxPriceFormControl.reset();
      }
      temp.attributeValue = value;
    }

    this.filterService.toggleFilter(temp);
    this.filters = this.filterService.getFilter();
    this.updateChips();
  }

  initializeFilters() {
    this.chipFilters = [];
    this.minimum = 0;
    this.maximum = 0;
    this.transactionType_Venta = false;
    this.transactionType_Renta = false;
    this.propertyType_Casa = false;
    this.propertyType_Departamento = false;
    this.propertyType_BodegaComercial = false;
    this.propertyType_Edificio = false;
    this.propertyType_LocalComercial = false;
    this.propertyType_LocalenCentroComercial = false;
    this.propertyType_Oficina = false;
    this.propertyType_Terreno = false;
    this.propertyType_TerrenoComercial = false;
    this.filteredProperties = this.propertyService.getAll();
  }

  filterSelectionToggle(value: string) {
    var temp: SingleFilter = new SingleFilter();

    TREE_DATA.some((filter: Filter) => {
      if (filter.attributeValues.some(item => item === value)) {
        temp.attributeName = filter.attributeName;
        temp.attributeValue = value;
        return true;
      }
    });
    this.filterService.toggleFilter(temp);
    this.filters = this.filterService.getFilter();
    this.updateChips();
  }

  updateChips() {
    this.chipFilters = [];
    if (this.filters.length > 0) {
      this.filters.forEach((filter: Filter) => {
        var filterValues = filter.attributeValues;
        filterValues.map((value: string) => {
          this.chipFilters.push(this.attributes[filter.attributeName] + ": " + value);
        });
      });
    }
    this.applyFilters();
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  radioChange(value:string){
    var realValue = value.substring(0,1); //number
    var temp: SingleFilter = new SingleFilter();
    if(value.includes("Recámaras")){
      temp.attributeName = "Bedrooms";
    }else {
      temp.attributeName = "Bathrooms";
    }
    temp.attributeValue = realValue;
    this.filterService.changeFilterValue(temp);
    this.filters = this.filterService.getFilter();
    this.updateChips();
  }

  filterButtonClick(){
    const dialogRef = this.dialog.open(FilterPopupComponent, {
      maxWidth: '100%',
      maxHeight: '90vh',
      height: 'fit-content'
    });

    dialogRef.afterClosed().subscribe((result:FilterViewModel) => {
      if(result){
        this.filterService.clearFilter(); //Reset filters
        const keys = Object.keys(result);
        keys.forEach(key => {
          var filter: SingleFilter = new SingleFilter();
          if(key.includes("Type")) { //PropertyType or TransactionType
            if(result[key]){
              var name = key.split('_')[0];

              //have to convert the value from pascal case to a sentence
              var text = key.split('_')[1];
              var value = text.replace( /([A-Z])/g, " $1" ).trim();
              
              filter.attributeName = name;
              filter.attributeValue = value;
              this.filterService.toggleFilter(filter);
            }
          }
          else if(key.startsWith("selected")) { //bathrooms or bedrooms
            if(result[key] != ""){
              var realValue = result[key].substring(0,1); //number
              if(result[key].includes("Recámaras")){
                filter.attributeName = "Bedrooms";
              }else {
                filter.attributeName = "Bathrooms";
              }
              filter.attributeValue = realValue;
              this.filterService.changeFilterValue(filter);
            }
          }else { //maximum or minimum
            filter.attributeName = key;
            filter.attributeValue = result[key];
            var value = result[key] + "";
            const regex = /^[0]*\.[0]*$/g
            var found = value.match(regex);
            if(value.trim() !== "" && value.trim() !== "0" && !found){
              this.filterService.changeFilterValue(filter);
            }
          }
        });
        this.filters = this.filterService.getFilter();
        this.filterService.update();
      }
      //this.animal = result;
    });
  }

  assignPrice(value:string, attribute) {
    this[attribute] = value;
    var temp: SingleFilter = new SingleFilter();
    temp.attributeName = attribute;
    temp.attributeValue = value;

    const regex = /^[0]*\.[0]*$/g
    var found = value.match(regex);
    if(value.trim() === "" || value.trim() === "0" || found){
      this.filterService.clearSingleFilter(attribute);
    }else{
      this.filterService.changeFilterValue(temp);
    }
    this.filters = this.filterService.getFilter();
    this.updateChips();
  }

  clearPriceValue(attribute){
    var temp: SingleFilter = new SingleFilter();
    temp.attributeName = attribute;
    if(attribute==="minimum"){
      temp.attributeValue = this.minPriceFormControl.value;
      this.minPriceFormControl.reset();
      this.minimum = 0;
    }
    if(attribute==="maximum"){
      temp.attributeValue = this.maxPriceFormControl.value;
      this.maxPriceFormControl.reset();
      this.maximum = 0;
    }
    this.filterService.toggleFilter(temp);
    this.filters = this.filterService.getFilter();
    this.updateChips();
  }

  applyFilters(){
    this.filteredProperties = this.propertyService.getAll();
    this.filteredProperties = this.filteredProperties.filter(item => {
      if(this.filters.length > 0){
        //var result = false; //not included initially
        var containsTransType = false;
        var transTypeResult = false;

        var containsPropType = false;
        var propTypeResult = false;

        var containsMinimum = false;
        var minimumResult = false;

        var containsMaximum = false;
        var maximumResult = false;

        var containsBedroom = false;
        var bedroomResult = false;

        var containsBathroom = false;
        var bathroomResult = false;
        this.filters.forEach((filter:Filter) => {
          if(filter.attributeName === "transactionType"){
            containsTransType = true;
            filter.attributeValues.forEach(value => {
              if(!transTypeResult){
                transTypeResult = item.transactionType == value;
              } 
            });
          }
          if(filter.attributeName === "propertyType"){
            containsPropType = true;
            filter.attributeValues.forEach(value => {
              if(!propTypeResult){
                propTypeResult = item.propertyType == value;
              }
            });
          }
          if(filter.attributeName === "minimum"){
            containsMinimum = true;
            minimumResult = item.price >= Number(filter.attributeValues[0]);
          }
          if(filter.attributeName === "maximum"){
            containsMaximum = true;
            maximumResult = item.price <= Number(filter.attributeValues[0]);
          }
          if(filter.attributeName === "Bedrooms"){
            containsBedroom = true;
            bedroomResult = item.bedrooms >= Number(filter.attributeValues[0]);
          }
          if(filter.attributeName === "Bathrooms"){
            containsBathroom = true;
            bathroomResult = item.bathroomsComplete >= Number(filter.attributeValues[0]);
          }
        });
        
        var result = (!containsTransType || transTypeResult) &&
                (!containsPropType || propTypeResult) &&
                (!containsMinimum || minimumResult) &&
                (!containsMaximum || maximumResult) &&
                (!containsBedroom || bedroomResult) &&
                (!containsBathroom || bathroomResult);
        return result;
      }else{
        return true;
      }
    });
  }


}
