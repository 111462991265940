import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Property } from '../Shared/Property.model';
import { PropertyService } from '../Shared/property.service';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faShower } from '@fortawesome/free-solid-svg-icons';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { ContactComponent } from '../contact/contact.component';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements AfterViewInit {

  property: Property;
  faWhatsapp = faWhatsapp;
  faShower = faShower;
  safeURL;
  arrowsOutside = true;

  constructor(private activated: ActivatedRoute,
    private _service: PropertyService,
    private _sanitizer: DomSanitizer,
    public dialog: MatDialog) {
      const id = this.activated.snapshot.paramMap.get('id')
      this.property = this._service.getProperty(id);
      if(this.property.video){
        var linkWithOptions = this.property.video + "?fs=0&modestbranding=1&controls=0&rel=0"
        this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(linkWithOptions);
      }
      //console.log(this.property);
    }

  ngAfterViewInit() {
    var text = document.getElementById("propBody");
    text.innerHTML = this.property.description;

    if(document.documentElement.clientWidth <= 425){
      this.arrowsOutside = false;
    }else{
      this.arrowsOutside = true;
    }
  }

  openDialog() {
    const dialogRef = this.dialog.open(ContactComponent, {
      data: this.property.id
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  onResize(event) {
    if(event.target.innerWidth <= 425){
      this.arrowsOutside = false;
    } else{
      this.arrowsOutside = true;
    }
  }
}
