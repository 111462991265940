<div class="main-container">
    <div class="filters-container">

        <mat-accordion multi class="filter-accordion">
            <mat-expansion-panel [expanded]="transactionExpanded" class="filter-header">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Tipo de Transacción
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="transType-container">
                    <mat-checkbox [(ngModel)]="transactionType_Renta" (click)="filterSelectionToggle('Renta')"
                        [color]="'primary'">
                        Renta</mat-checkbox>

                    <mat-checkbox [(ngModel)]="transactionType_Venta" (click)="filterSelectionToggle('Venta')"
                        [color]="'primary'">
                        Venta</mat-checkbox>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="propertyTypeExpanded" class="filter-header">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Tipo de Propiedad
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="propType-container">
                    <mat-checkbox [(ngModel)]="propertyType_Casa" (click)="filterSelectionToggle('Casa')"
                        [color]="'primary'">
                        Casa</mat-checkbox>

                    <mat-checkbox [(ngModel)]="propertyType_Departamento"
                        (click)="filterSelectionToggle('Departamento')" [color]="'primary'">
                        Departamento</mat-checkbox>

                    <!--mat-checkbox [(ngModel)]="propertyType_BodegaComercial"
                        (click)="filterSelectionToggle('Bodega Comercial')" [color]="'primary'">
                        Bodega Comercial</mat-checkbox-->

                    <mat-checkbox [(ngModel)]="propertyType_Edificio" (click)="filterSelectionToggle('Edificio')"
                        [color]="'primary'">
                        Edificio</mat-checkbox>

                    <mat-checkbox [(ngModel)]="propertyType_LocalComercial"
                        (click)="filterSelectionToggle('Local Comercial')" [color]="'primary'">
                        Local Comercial</mat-checkbox>

                    <!--mat-checkbox [(ngModel)]="propertyType_LocalenCentroComercial"
                        (click)="filterSelectionToggle('Local en Centro Comercial')" [color]="'primary'">
                        Local en Centro Comercial</mat-checkbox-->

                    <mat-checkbox [(ngModel)]="propertyType_Oficina" (click)="filterSelectionToggle('Oficina')"
                        [color]="'primary'">
                        Oficina</mat-checkbox>

                    <mat-checkbox [(ngModel)]="propertyType_Terreno" (click)="filterSelectionToggle('Terreno')"
                        [color]="'primary'">
                        Terreno</mat-checkbox>

                    <!--mat-checkbox [(ngModel)]="propertyType_TerrenoComercial"
                        (click)="filterSelectionToggle('Terreno Comercial')" [color]="'primary'">
                        Terreno Comercial</mat-checkbox-->
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="priceExpanded" class="filter-header price-filter">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Precio
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="transType-container">
                    <mat-form-field class="price-input">
                        <mat-label>Mínimo</mat-label>
                        <span matPrefix>$ &nbsp;</span>
                        <input matInput type="text" [formControl]="minPriceFormControl" (keyup)="assignPrice($event.srcElement.value, 'minimum')" autocomplete="off">
                        <button mat-button *ngIf="minimum!==0" matSuffix mat-icon-button (click)="clearPriceValue('minimum')">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="minPriceFormControl.hasError('pattern')">
                            Cantidad inválida
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="price-input">
                        <mat-label>Máximo</mat-label>
                        <span matPrefix>$ &nbsp;</span>
                        <input matInput type="text" [formControl]="maxPriceFormControl" (keyup)="assignPrice($event.srcElement.value, 'maximum')" autocomplete="off">
                        <button mat-button *ngIf="maximum!==0" matSuffix mat-icon-button (click)="clearPriceValue('maximum')">
                            <mat-icon>close</mat-icon>
                        </button>
                        <mat-error *ngIf="maxPriceFormControl.hasError('pattern')">
                            Cantidad inválida
                        </mat-error>
                    </mat-form-field>
                </div>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="bedroomsExpanded" class="filter-header">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Recámaras
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="propType-container">
                    <mat-radio-group aria-labelledby="example-radio-group-label"
                        [(ngModel)]="selectedBedroom" (change)="radioChange($event.value)">
                        <mat-radio-button class="filter-radio-button" *ngFor="let bedroom of bedrooms" [value]="bedroom" [color]="'primary'">
                            {{bedroom}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel [expanded]="bathroomsExpanded" class="filter-header">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Baños
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <div class="propType-container">
                    <mat-radio-group aria-labelledby="example-radio-group-label" class="bathrooms-radios"
                        [(ngModel)]="selectedBathroom" (change)="radioChange($event.value)">
                        <mat-radio-button class="filter-radio-button" *ngFor="let bathroom of bathrooms" [value]="bathroom" [color]="'primary'">
                            {{bathroom}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
    <div class="main-content-container">
        <button mat-raised-button color="primary" class="filter-button" (click)="filterButtonClick()">
            <mat-icon style="margin-right: 5px;">filter_alt</mat-icon>
            Filtros
        </button>
        <div *ngIf="chipFilters.length > 0" class="chips">
            Filtros:
            <mat-chip-list #chipList class="chip-list">
                <mat-chip *ngFor="let filter of chipFilters" [selectable]="selectable" [removable]="removable"
                    class="filter-chips" (removed)="remove(filter)">
                    {{filter}}
                    <mat-icon matChipRemove (click)="remove(filter)">cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </div>
        <div class="cards-container" [@listAnimation]="filteredProperties.length">
            <app-card *ngFor="let prop of filteredProperties" [property]="prop" class="filtered-card"></app-card>
        </div>
    </div>
</div>