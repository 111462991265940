import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SingleFilter } from '../filter/filter.model';
import { FilterService } from '../filter/filter.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit  {

  //userIsOnHome : boolean;

  constructor(private router: Router,
    private filterService: FilterService) { }

  ngOnInit() {}

  scrollToElement(selector) {
    const element = document.getElementById(selector)
    element ? element.scrollIntoView({ behavior: "smooth", block: "center" }) : null;
  }

  filterProps(value: string){
    var filter: SingleFilter = new SingleFilter();
    this.filterService.clearFilter();
    if(value == "Renta" || value == "Venta"){
      filter.attributeName = "transactionType";
      filter.attributeValue = value;
      this.filterService.addFilter(filter);
    }else{
      filter.attributeName = "propertyType"; //Terrenos
      filter.attributeValue = "Terreno";
      this.filterService.addFilter(filter);
      /*filter.attributeValue = "Terreno Comercial";
      this.filterService.addFilter(filter);*/
    }
    this.filterService.update();
    this.router.navigate(['/filter']);
  }
}
