export class Property {
    id: string;
    relevant: boolean; //only six properties should have this turned on
    available: boolean;
    transactionType: TransactionType;
    propertyType: PropertyType;
    price?: number;
    priceUsd?: number;
    unit?: string; //puede ser HTML
    floor?: number;
    //title: string; //Compuesto de "<propertyType> en <zone>, <city>"
    zone: string;
    stories?: number;
    bathroomsComplete?: number;
    bathroomsHalf?: number;
    parkingSpaces?: number;
    bedrooms?: number;
    constructionArea?: number; //in square meters
    landArea?: number; //in square meters
    description: string; //puede ser HTML
    features: string[];
    coordinates?: Coordinates;
    images: Image[];
    address?: string;
    video?: string;
    city: string; //country included
}

export enum TransactionType {
    ForRent = 'Renta',
    ForSale = 'Venta'
}

export const TransactionTypeArray = [
    { key: 0, value: 'Renta' },
    { key: 1, value: 'Venta' }
  ];

//no comentar los tipos aqui (puede causar inconsistencias en los indices)
export enum PropertyType {
    House = 'Casa',
    Department = 'Departamento',
    Warehouse = 'Bodega Comercial',
    Building = 'Edificio',
    DepLocal = 'Local',
    ComercialLocal = 'Local Comercial',
    LocalShoppingCenter = 'Local en Centro Comercial',
    Office = 'Oficina',
    DepOther = 'Otro',
    Land = 'Terreno',
    LandComercial = 'Terreno Comercial'
}

export const PropertyTypeArray = [
    {key: 0, value: 'Casa'},
    {key: 1, value: 'Departamento'},
    //{key: 2, value: 'Bodega Comercial'},
    {key: 3, value: 'Edificio'},
    //{key: 4, value: 'Local'},
    {key: 5, value: 'Local Comercial'},
    //{key: 6, value: 'Local en Centro Comercial'},
    {key: 7, value: 'Oficina'},
    //{key: 8, value: 'Otro'},
    {key: 9, value: 'Terreno'},
    //{key: 10, value: 'Terreno Comercial'},
  ];

export interface Coordinates {
    x: number;
    y: number;
}

export class Attributes {
    available = "Disponible";
    transactionType = "Tipo de Transacción";
    propertyType = "Tipo de Propiedad";
    minimum = "Precio Mínimo";
    maximum = "Precio Máximo";
    //title: string; //Compuesto de "<propertyType> en <zone>, <city>"
    zone = "Zona";
    stories = "Plantas";
    Bathrooms = "Baños";
    parkingSpaces = "Espacios de Estacionamiento";
    Bedrooms = "Recámaras";
    constructionArea = "Area de Construcción"; //in square meters
    landArea = "Area de Terreno"; //in square meters
    city = "Ciudad"; //country included
}

export class Image {
    path: string;
    constructor(_path: string){
        this.path = _path;
    }
}