import { Injectable } from '@angular/core';
import { Property, PropertyType, TransactionType, Image } from './Property.model';
import { HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  exchangeRate: number;
  baseUrl:string = "https://realestateapi.azurewebsites.net/api/"; //production

  private properties: Property[] = [

// #region Maria Guadalupe  

  {id: "MG-1079-2332",
  relevant: true, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.ComercialLocal ,
  price: 62500,
  zone: "La Marina",
  constructionArea: 200, //in square meters
  landArea: 327, //in square meters
  description: `Local en renta en Plaza Amura, frente a Galerías, excelente para restaurante, café, bar, oficina, en el pleno corazón de la Marina Mazatlán. <br><br>
  Local ancla ubicado en el segundo piso, se entrega en obra gris, listo para hacer las adecuaciones para el negocio que usted necesite ofrecer al público en la mejor zona de Mazatlán. <br><br>
  Consta de 200 m<sup>2</sup> de área cerrada, más 127 m<sup>2</sup> de terraza privativa, la cual usted podrá adaptar para una hermosa vista desde y hacia la Plaza Galerías; al utilizar este hermoso espacio, usted tendrá 327 m<sup>2</sup> que puede ofrecer a sus clientes, para que tengan una estancia cómoda y espectacular. <br><br>
  Plaza Amura cuenta con elevador, facilitando así el acceso de todos sus clientes. <br><br>
  Este local está ubicado cerca de Centros Comerciales, Escuelas, Hospitales, desde donde usted podrá captar clientes para su negocio. Si quiere un local que esté a la altura de su negocio, comuníquese con nosotros! `,
  features: ["Vista panorámica", "Elevador"],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r1.jpg"), 
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2332/r19.jpg"),
],
  video: "https://www.youtube.com/embed/9Aup88PghOk",
  city: "Mazatlan, Sinaloa"},  

  {id: "MG-1079-2350",
  relevant: true, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 2500000,
  floor: 4,
  stories: 1,
  zone: "Palos Prietos",
  bathroomsComplete: 2,
  parkingSpaces: 1,
  bedrooms: 3,
  constructionArea: 100.6, //in square meters
  landArea: 100.6, //in square meters
  description: `Excelente Departamento de tres recámaras, en el que tu familia completa podrá disfrutar de la cercanía a la playa, ya que está a tan sólo 4 cuadras del hermoso malecón de Mazatlán! <br><br>
  Si lo adquieres con el fin de hacer una inversión, es una opción muy inteligente ya que hay muy pocos departamentos en renta que tengan 3 recámaras, así que tienes ventaja comparándolos con las unidades de 2 recámaras. <br><br>
  Además, su privilegiada ubicación, además de céntrica, localizado a tan sólo 4 cuadras del malecón, tienes la opción de ir a hacer ejercicio al mejor lugar de Mazatlán: su malecón! Con gran cercanía de escuelas, iglesias, hospitales, supermercados, plazas comerciales, etc. nos hace sentir que estamos en el corazón de Mazatlán! <br><br>
  En el cuarto piso de un magnífico desarrollo vertical con un Roof Top donde puede tener disfrutar de asadores y espacios de terraza, además de vista panorámica a varios rumbos de la ciudad! Listo para que traigas sólo tu maleta, ya que se entrega amueblado y completamente equipado! <br><br>
  Espacioso departamento de 100.60 metros cuadrados, <br><br>
  <ul> 
  <li> 3 habitaciones. </li>
  <li> 2 baños completos con canceles y accesorios. </li>
  <li> completamente amueblado. </li>
  <li> cocina integral con cubierta de granito. </li>
  <li> barra desayunadora. </li>
  <li> estufa de gas. </li>
  <li> closets en recamaras. </li>
  <li> mini-splits    </li>
  <li> lavandería, etc. </li>
  </ul>
  <br>
  No dudes en pedir más información de esta oportunidad única que no debes dejar pasar!`,
  features: ["Muy céntrico", "Autobús cercano", "Colegio cercano", "Aire Acondicionado", "Desayunador",
             "Baño en Habitación", "Ubicación accesible", "Gimnasio cercano", "Tiendas cercanas", "Balcón",
             "Amueblado", "Moderno", "Accesible al Tráfico", "Hospital cercano", "Zona Concurrida", "Terraza",
             "Cuarto de lavado", "Edificio Nuevo", "Cerca de la Playa", "Cerca de Avenida", "Zona Tranquila",
             "Puerta Delantera de Acero", "Electrodomésticos Incluidos" ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2350/r14.jpg"),
   
 ],
  city: "Mazatlan, Sinaloa"},

// #endregion

// #region Brenda Hinojos

  {id: "BH-1079-2268",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 17500,
  zone: "Paseo Claussen",
  bathroomsComplete: 2,
  bedrooms: 2,
  constructionArea: 85, //in square meters
  landArea: 105, //in square meters
  description: `Disfruta Mazatlán en este hermoso departamento sobre el malecón <br><br>
  Localizado a escasos metros del monumento al pescador, muy cerca del centro histórico y a unos minutos de zona dorada. <br><br>
  Completamente amueblado y equipado con hermosos acabados, el departamento cuenta con sala, cocina integral con barra de granito, solárium para disfrutar de la vista del malecón, 2 recamaras con cama matrimonial, clóset y tocador cada una con baño completo y área de servicio. <br><br>
  Cuenta con wifi, lavadora, guía interactivo para consultar los mejores lugares en Mazatlán y un par de hermosas bicicletas para recorrer el malecón más largo de México y las hermosas vistas que te brinda el bello puerto. <br><br>
  **El precio anunciado es por semana** <br><br>
  **Pregunte por nuestras tarifas por noche**<br><br>
  **Estancia mínima tres noches**`,
  features: [ "Remodelado",
              "Acceso fluvial",
              "Muy céntrico",
              "Ubicación accesible",
              "Densidad de Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Avenida",
              "Colegio cercano",
              "Zona Concurrida",
              "Por el Mar",
              "Sur",
              "Aire Acondicionado",
              "Cable",
              "Electricidad",
              "Gas",
              "Vista a la Ciudad",
              "Vista al Mar",
              "Vista a Playa",
              "Vista panorámica",
              "Balcón",
              "Terraza",
              "Desayunador",
              "Amueblado",
              "Baño en Habitación",
              "Barra de Desayuno",
              "Cuarto de Servicio",
              "Contemporáneo"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2268/r13.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-2264",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 12000,
  zone: "Masaya (Sábalo Country)",
  bathroomsComplete: 2,
  bedrooms: 2,
  constructionArea: 85, //in square meters
  landArea: 85, //in square meters
  parkingSpaces: 1,
  floor: 1,
  description: `¡Sin duda una oportunidad que no puedes dejar pasar! <br><br>
  Estrena este departamento de 85 m<sup>2</sup> en planta baja en Sabalo Country.<br><br>
  Cuenta con dos recamaras con clósets y dos baños completos, sala, comedor, cocina integral con barra de granito, lavandería, patio interior, un cajón de estacionamiento.<br><br>
  El edificio consta de 7 departamentos, en 3 niveles y un roof garden con una increíble vista, excelente ubicación en esquina a tan solo unas cuadras de la hermosa playa de Mazatlán.`,
  features: [ "A estrenar",
              "Ubicación accesible",
              "Cerca de la Playa",
              "Autobús cercano",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Norte",
              "Aire Acondicionado",
              "Electricidad"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2264/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2264/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2264/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2264/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2264/r5.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-2245",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 15000,
  zone: "Gaviotas Golden",
  bathroomsComplete: 2,
  bedrooms: 2,
  constructionArea: 100, //in square meters
  landArea: 100, //in square meters
  parkingSpaces: 1,
  floor: 3,
  description: `Disfruta de este departamento en una de las mejores ubicaciones de Mazatlán, a tan solo unos pasos se encuentran las playas, restaurantes, locales comerciales y mucho más! <br><br>
  Amplio departamento completamente equipado y amueblado, cuenta con sala, cocina y comedor. El departamento consta de 2 recamaras y 2 baños completos. <br><br>
  Este condominio cuenta con alberca en planta baja, área de asadores y terraza con una hermosa vista, dos pequeños gimansios, estacionamientos cerrado con portón e interfón.`,
  features: [ "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Por el Mar",
              "Aire Acondicionado",
              "Portón Eléctrico",
              "Cable",
              "Electricidad",
              "Gimnasio",
              "Terraza",
              "Alberca",
              "Estacionamiento Cerrado",
              "Amueblado",
              "Cuarto de lavado",
              "Closet Construído"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2245/r16.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-2240",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.House,
  price: 19500,
  zone: "Palmilla a unos pasos del mar",
  bathroomsComplete: 3,
  bathroomsHalf: 1,
  bedrooms: 3,
  constructionArea: 126, //in square meters
  landArea: 123.5, //in square meters
  parkingSpaces: 2,
  stories: 2,
  description: `Este complejo consta de 3 cotos privados con acceso independiente y área de departamentos, localizado en la zona de Sábalo Cerritos muy cerca del mar en Mazatlán. <br><br>
  Esta hermosa casa modelo Alondra Plus, está localizada en el circuito palmas, cuenta con áreas comunes con hermosos jardines y área de asadores, juegos infantiles, piñatero, seguridad 24 horas, acceso controlado y mucho más para que tu familia viva con tranquilidad y armonía. <br><br>
  Completamente nueva puedes disfrutar de la propiedad <br><br>
  En planta baja cuenta con cochera techada para dos autos, al ingresar tiene medio baño para el área social, espacio de sala comedor y amplia cocina integral completamente equipada con parrilla, campana, horno y microondas empotrado y cubierta de granito. <br><br>
  Cuenta con cuarto de lavado con acceso independiente, boiler y un amplio patio.  <br><br>
  La casa se renta completamente equipada con minisplits en área de sala comedor y en cada una de sus recamaras, las cuáles se encuentran ubicadas en la planta alta y cada una con baño propio y clóset. <br><br>
  Asimismo cuenta con persianas en todas las ventanas. <br><br>
  **No incluye servicios**`,
  features: [ "A estrenar",
              "Portero",
              "Acceso fluvial",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Por el Mar",
              "Norte",
              "Aire Acondicionado",
              "Seguridad - Vigilancia",
              "Vista Amplia",
              "Jardín",
              "Espacio para juegos",
              "Desayunador",
              "Recibidor",
              "Sin Amueblar",
              "Barra de Desayuno",
              "Cuarto de Servicio"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2240/r19.jpg") 
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-2167",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 7500000,
  zone: "Club Real Residencial",
  bathroomsComplete: 4,
  bathroomsHalf: 1,
  bedrooms: 4,
  parkingSpaces: 2,
  stories: 2,
  description: `Hermosa casa en venta ubicada en uno de los condominios mas exclusivos de Mazatlán. <br><br>
  Ubicada en privada cerrada muy cerca de dos albercas y canchas de tenis. <br><br>
  Completamente equipada, esta casa cuenta con 4 recamaras y 4.5 baños, una de ellas en planta baja con su baño propio. <br><br>
  Cocina española equipada con electrodomésticos de alta calidad, barra de cuarzo. <br><br>
  La recamara principal cuenta con su baño vestidor muy amplio y tiene acceso a una terraza en planta alta. <br><br>
  La lavandería se encuentra en la planta alta. <br><br>
  Tiene un patio muy grande con jardín y área de terraza techada. <br><br>
  Cochera techada para dos autos.`,
  features: [ "Muy céntrico",
              "Ubicación accesible",
              "Cerca de la Playa",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Aire Acondicionado",
              "Vista a Área Abierta",
              "Balcón",
              "Jardín",
              "Terraza",
              "Cancha de Tenis",
              "Alberca",
              "Baño de servicio",
              "Sin Amueblar",
              "Baño en Habitación",
              "Barra de Desayuno"
  
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r28.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r29.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r30.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r31.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r32.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r33.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r34.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r35.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r36.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r37.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r38.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r39.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r40.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r41.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r42.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r43.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r44.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r45.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r46.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r47.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r48.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r49.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r50.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2167/r51.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-2157",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 13000,
  zone: "Azul Pacífico",
  bathroomsComplete: 2,
  bedrooms: 2,
  constructionArea: 85, //in square meters
  landArea: 85, //in square meters
  parkingSpaces: 1,
  description: `Hermoso departamento en renta en Cerritos, una de la mejores zonas turísticas de Mazatlán, a unos metros de la playa y del parque acuático, restaurantes, y bares. <br><br>
  Disfruta de la comodidad de este bello departamento en primer piso frente a área común <br><br>
  El departamento cuenta con: <br><br>
  2 recamaras con 2 baños completos (1 recamara con cama matrimonial y 1 recámara con litera individual y matrimonial), cocina equipada, sala, comedor, tv, 3 mini splits <br><br>
  Área común con alberca y terraza`,
  features: [ "A estrenar",
              "Cerca de la Playa",
              "Cerca de Campo de Golf",
              "Cerca de Avenida",
              "Por el Mar",
              "Norte",
              "Aire Acondicionado",
              "Terraza",
              "Amueblado",
              "Elevador",
              "Baño en Habitación",
              "Barra de Desayuno",
              "Cuarto de Servicio"
  
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2157/r16.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
  
  {id: "BH-1079-1891",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 14000,
  zone: "Paraíso Gaviotas",
  bathroomsComplete: 2,
  bedrooms: 2,
  constructionArea: 75, //in square meters
  landArea: 75, //in square meters
  parkingSpaces: 1,
  floor: 2,
  description: `Estrena este hermoso Departamento en renta en paraíso gaviotas, ubicado en zona dorada. <br><br>
  Se localiza en el Segundo Piso, está completamente Amueblado y equipado. <br><br>
  Cuebta con 2 recamaras con closet y minisplits, 2 Baños completos y centro de lavado <br><br>
  El edifico cuenta con un amplio Roof garden con jacuzzi y terraza, Estacionamiento cerrado con portón electrico`,
  features: [ "Aire",
              "AcondicionadoPortón", 
              "EléctricoAmueblado"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1891/r12.jpg")

 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-1704",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.ComercialLocal,
  price: 1250000,
  zone: "Villa Unión",
  constructionArea: 129.78, //in square meters
  landArea: 66.39, //in square meters
  description: `Local perfecto para giro comercial, gran flujo de personas, zona muy concurrida. <br><br>
  Excelente Oportunidad de Inversión! Se vende en la sindicatura de Villa Unión, junto a la plazuela principal, especial para instalar un negocio. <br><br>
  El edificio cuenta con dos plantas, un área de servicios y un baño.`,
  features: [ "Remodelado",
              "En el corazón de la ciudad",
              "Muy céntrico",
              "Accesible al Tráfico",
              "Densidad de Tráfico",
              "Cerca de Aeropuerto",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Zona Concurrida",
              "Electricidad",
              "Sin Amueblar"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1704/r20.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-1694",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 1600000,
  zone: "Coto San Gabriel en Hacienda del Seminario",
  bathroomsComplete: 2,
  bedrooms: 3,
  constructionArea: 76.82, //in square meters
  landArea: 139.34, //in square meters
  parkingSpaces: 2,
  description: `Casa en venta en Coto San Gabriel, Hacienda del Seminario, en Mazatlan. <br><br>
  Hacienda del seminario es en una zona privilegiada, a 2 minutos de Plaza Sendero y supermercados, 3 minutos de Galerías y Marina. A 5 minutos del nuevo libramiento, de las mejores escuelas y del malecón. Cuentan con espacios que la familia de hoy necesita, casas singulares para vidas únicas, invierte en la propiedad ideal para tu estilo de vida. <br><br>
  Esta casa se ubica en uno de los primeros cotos con alberca y amenidades, lo cual la hace muy accesible a diversas zonas y comercios. <br><br>
  Se encuentra ubicada en calle privada dentro del coto, cuenta con terreno excedente. Su cómoda distribucion la hace aun mas atractiva ya que en planta baja cuenta con sala, comedor, cocina, una recamara y un baño completo, un amplio patio y entrada para cochera. En planta alta cuenta con dos recamaras y un baño completo. La casa se vende semi amueblada y equipada con protecciones y minisplits.`,
  features: [ "Prefabricado",
              "Acceso fluvial",
              "Muy céntrico",
              "Ubicación accesible",
              "Densidad de Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Aire Acondicionado",
              "Electricidad",
              "Gas",
              "Seguridad - Sistema",
              "Vista a Área Abierta",
              "Garage",
              "Recibidor",
              "Electrodomésticos Incluidos",
              "Barra de Desayuno",
              "Cuarto de Servicio",
              "Recámara Planta Baja"
  
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1694/r19.jpg")
 ],
  city: "Mazatlan, Sinaloa"}, 

  {id: "BH-1079-1669",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 30000,
  zone: "Torre VUE en Olas Altas",
  bathroomsComplete: 2,
  bedrooms: 2,
  constructionArea: 100, //in square meters
  landArea: 100, //in square meters
  parkingSpaces: 1,
  floor: 6,
  description: `Disfruta unas agradables vacaciones en este departamento estilo colonialcon una hermosa vista al mar ubicando en una de las mejores zonas de Mazatlán. El departamento cuenta con cocina completamente equipada, sala, desayunador, cuarto de lavado, 2 baños completos, 2 recamaras y una terraza a lo largo del departamento.  <br><br>
  El edificio tiene una de las pocas piscinas climatizadas en Mazatlán con una gran terraza para sus reuniones, camastros y asadores! Servicio de vigilancia las 24 horas, 2 ascensores, estacionamiento privado, gimnasio y más comodidades. ¡A solo unas pocas cuadras de las zonas históricas más hermosas de México!`,
  features: [ "Muy céntrico",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Zona Concurrida",
              "Por el Mar",
              "Internet de Alta Velocidad",
              "Aire Acondicionado",
              "Sistema de Aire Central",
              "Electricidad",
              "Gimnasio",
              "Ascensor",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Acceso para silla de ruedas",
              "Vista al Mar",
              "Vista panorámica",
              "Balcón",
              "Garaje con Puerta Automática",
              "Terraza",
              "Mascotas permitidas",
              "Estacionamiento Cerrado",
              "Elevador",
              "Lavandería (en edificio)",
              "Electrodomésticos Incluidos",
              "Baño en Habitación"
  
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1669/r15.jpg")
 ],
  city: "Mazatlan, Sinaloa"}, 

  {id: "BH-1079-1603",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Office,
  price: 4200000,
  zone: "El Fracc. El Toreo",
  constructionArea: 304, //in square meters
  landArea: 196, //in square meters
  parkingSpaces: 4,
  stories: 2,
  description: `Si estas buscando invertir en una de las zonas más solicitadas de Mazatlán, te va a interesar este inmueble! <br><br>
  Ponemos a tus órdenes esta propiedad en venta en el Fracc. El Toreo a solo una cuadra de la Av. Rafael Buelna, atrás del hotel City Express. Actualmente es utilizada como oficina, sin embargo cuenta con gran potencial, ya que se puede adaptar para convertir en casa habitación o hacer dos departamentos con entrada independiente. Un inmueble muy versátil con gran oportunidad de crecimiento y aprovechamiento de espacios. <br><br>
  Cuenta en planta baja con cochera cerrada con porton eléctrico y cancel, su distribución consiste en una amplia bodega y oficina con baño completo y al fondo escaleras con acceso directo a la terraza de la planta alta. <br><br>
  En planta alta, con acceso directo a calle y entrada independiente, al subir nos encontramos con un amplio salón utilizado como área de recepción, 3 recámaras o despachos muy amplios con closets y archivos, un baño con posibilidad de ampliar y hacer baño vestidor. También cuenta con área de cocina, una terraza en la parte posterior que da acceso a planta baja. <br><br>
  Una excelente oportunidad de inversión! Espacios muy funcionales y ubicación inigualable.`,
  features: [ "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Aire Acondicionado",
              "Portón Eléctrico",
              "Agua Corriente",
              "Balcón",
              "Terraza",
              "Portón Eléctrico",
              "Recibidor"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1603/r26.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-1587",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForRent,
  propertyType: PropertyType.Department,
  price: 25000,
  zone: "Marina del sol",
  constructionArea: 100, //in square meters
  landArea: 100, //in square meters
  parkingSpaces: 1,
  bathroomsComplete: 2,
  bedrooms: 2,
  bathroomsHalf: 1,
  floor: 5,
  description: `Se renta departamento a estrenar con increible vista al mar y frente de playa. Localizado en la zona de cerritos, en la torre nueva de Marina del Sol en mazatlan Sinaloa. <br><br>
  Goza de hermosos atardeceres en este condominio de 2 recamaras y 2 baños y medio, sala, comedior, cocina integral con barra de granito completamente equipada. <br><br>
  A tan solo unos kilometros de zona comercial, 2 minutos de la marina y 5 minutos de zona dorada.`, 
  features: [ "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Cerca de Centro Médico",
              "Internet de Alta Velocidad",
              "Aire Acondicionado",
              "Cable",
              "Electricidad",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Vista al Mar",
              "Vista a Playa",
              "Vista panorámica",
              "Balcón",
              "Alberca",
              "Amueblado",
              "Elevador",
              "Electrodomésticos Incluidos",
              "Moderno"
  
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1587/r21.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-1268",
  relevant: true, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 47000000,
  zone: "Cerca de Centro de Convenciones",
  landArea: 41350 , //in square meters
  description: `Se venden 41,500 m<sup>2</sup> en zona de alto crecimiento en Mazatlan. Muy cerca de avenidas principales, a 5 minutos de el entronque con la maxipista, a pocos km de galerias y zona de la marina y zona dorada. <br><br>
  Terreno Ideal para desarrollos inmobiliarios o comerciales, a unos metros de la Av. Oscar perez  (Libramiento 2). Muy cerca del Centro de Usos Multiples (CUM) y el nuevo Hospital General de Mazatlán. <br><br>
  En esta zona se ha detonado el desrrollo en los ultimos 3 años, alta plusvalía garantizada. Cont'actenos para mayores informes. No pierda esta excelente oportunidad de inversión. <br><br>  
  Precio de venta: $1,100 por m<sup>2</sup>`, 
  features: [ "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Cerca de Centro Médico",
              "Noreste"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1268/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1268/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1268/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1268/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1268/r5.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-1160",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 1800000,
  zone: "Centro a unas cuadras del malecón",
  landArea: 134, //in square meters
  description: `Se vende Terreno a unas cuadras del Malecon! único terreno disponible en esta zona! <br><br>
  Cuenta con 134 m<sup>2</sup>, 8 metros de Frente y 16 metros de Fondo, <br><br>
  Poligono rectangular, a solo 3 minutos de Playa Norte, <br><br>
  Excelente para casa habitacion, comercio o conjunto de departamentos.`, 
  features: [ "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Avenida"
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1160/r8.jpg"),
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "BH-1079-721",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 6800000,
  zone: "MARINA REAL",
  landArea: 350 , //in square meters
  parkingSpaces: 2,
  bathroomsComplete: 4,
  bedrooms: 4,
  bathroomsHalf: 1,
  stories: 3,
  description: `Residencia en venta en una de las zonas más exclusivas de Mazatlán: el Fraccionamiento Marina Real dentro del Complejo Marina Mazatlán. Se encuentra a unos minutos de la playa, zona hotelera, Plaza Galerías, Plaza Senderos y la famosa Zona Dorada. La propiedad consta de 3 plantas distribuidas de la siguiente manera: La planta baja consta de una amplia sala comedor de doble altura , ½ baño debajo de la escalera, cocina integral equipada con barra cubierta en granito y electrodomésticos semi nuevos, cuarto de lavado, baño de servicio, terraza con un jardín al costado, patio trasero y cochera techada para 2 automóviles. En la planta alta se encuentran 3 amplias recámaras con sus respectivos baños y vestidores cada una. La recámara principal cuenta con balcón al frente . Una muy amplia sala de televisión. . La tercera planta tiene su área de gimnasio y un muy bonito roof garden.El fraccionamiento ofrece varias amenidades como alberca infinita, palapa para eventos, canchas de tenis, acceso a la Marina y seguridad privada 24/7. <br><br>
  Terreno 350 m<sup>2</sup> <br><br>
  Superficie construida 400 m<sup>2</sup>`, 
  features: [ "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Por el Mar",
              "Aire Acondicionado",
              "Seguridad - Vigilancia",
              "Vista Parcial a Marina",
              "Garage",
              "Jardín",
              "Cancha de Tenis",
              "Alberca",
              "Sin Amueblar",
              "Baño en Habitación",
              "Barra de Desayuno",
              "Moderno"
  
  ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-721/r24.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  // #endregion 

// #region Rafael Lizárraga

  {id: "RL-1079-2284",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 1067000,
  zone: "Fraccionamiento Veredas",
  landArea: 402.65, //in square meters
  description: `Con una vista espectacular de Mazatlán desde el Faro hasta la Zona de Cerritos este lote de 402.65 m<sup>2</sup> es único en su tipo. <br><br>
  Con varios metros de altura sobre el nivel del mar es el punto mas alto en Mazatlán con lo que podrás apreciar tanto amaneceres como atardeceres y disfrutar de un clima diferente al de una ciudad saturada en concreto, esto aunado a que Veredas Jardín Residencial no sólo se encuentra en el Nuevo Mazatlán, también es pionera al desarrollar la primera Comunidad Planeada; una comunidad autosustentable, es decir: la comunidad tendrá acceso a servicios básicos, amenidades, espacios sociales y la infraestructura para llevar a cabo diversas actividades sin tener que salir del desarrollo; dicha característica no lo tiene ningún otro desarrollo privado residencial en la ciudad, las cuales se limitan a una zona de amenidades. <br><br>
  Las principales características de Veredas Jardín Residencial son su contacto con la naturaleza debido a sus grandes áreas verdes y reservas naturales, casa club con alberca semi olímpica, gimnasio, terraza con vista al lago central del fraccionamiento, plaza comercial y seguridad privada. <br><br>
  Disfruta de estas grandes ventajas de vivir en un desarrollo pionero en su tipo y en un terreno en la mejor zona  del desarrollo.`,
  features: [ "Portero",
              "Acceso fluvial",
              "Campo",
              "En perímetro de bosque",
              "Ubicación accesible",
              "Cerca de un parque con juegos infantiles",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Cerca de Autopista",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Tranquila",
              "Oeste",
              "Vista panorámica",
              "Vista a Área Abierta"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2284/r20.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "RL-1079-2258",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 2499000,
  zone: "Sábalo Country Club",
  bathroomsComplete: 2,
  bedrooms: 2,
  landArea: 88.37, //in square meters
  constructionArea: 88.37,
  stories: 1,
  floor: 2,
  parkingSpaces: 1,
  description: `Experimentar de una vida cerca del mar no podría ser más fácil que en esta magnifica propiedad en zona dorada. <br><br>
  Este hermoso departamento se encuentra en el 2do nivel de un reciente complejo residencial, lo cual asegurara la tranquilidad y exclusividad que tanto deseas. <br><br>
  Con un fabuloso estilo moderno - minimalista, esta propiedad cuenta con 2 amplias habitaciones, 2 baños completos, áreas para sala de estar, comedor, cocina, área lavado. <br><br>
  Sube a la azotea del edificio para encontrar una amplia terraza donde podrás realizar tus eventos sociales y disfrutar de los atardeceres únicos de Mazatlán. <br><br>
  Sábalo Country Club es unos de los vecindarios más deseables de Mazatlán, pues es la zona turística más dinámica de toda la ciudad, donde tendrás a tu alcance los mejores restaurantes, centros comerciales, hospitales, colegios, centros nocturnos, galerías de arte, y mucho más. <br><br>
  Ya sea para vivir o como negocio de rentas vacacionales esta encantadora propiedad sin duda alguna es la oportunidad perfecta que estabas esperando, así que no lo dudes y contáctanos para conocer todas las ventajas de este fabuloso apartamento.`,
  features: [ "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Zona Concurrida",
              "Zona Tranquila",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Oeste",
              "Internet de Alta Velocidad",
              "Cable",
              "Electricidad",
              "Agua Corriente",
              "Tanque de Agua",
              "Balcón",
              "Terraza",
              "Mascotas permitidas",
              "Alberca",
              "Desayunador",
              "Sin Amueblar",
              "Cuarto de lavado",
              "Baño en Habitación",
              "Closet Construído",
              "Barra de Desayuno",
              "Cuarto de Servicio",
              "Moderno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2258/r22.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "RL-1079-2153",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 2200000,
  zone: "Central Park Tower",
  bathroomsComplete: 2,
  bedrooms: 2,
  landArea: 78 , //in square meters
  constructionArea: 78 ,
  floor: 1,
  parkingSpaces: 1,
  description: `Central Park Tower es un desarrollo residencial de alto potencial para generar altos rendimientos, pues se encuentra en un fraccionamiento privado a escasos pasos de uno de los lugares mas icónicos de Mazatlán. <br><br>
  Conformado por tan solo 5 condominios de 2 recamaras y 2 baños, lo cual asegurará el confort, privacidad y exclusividad que tanto deseas, además, la elegancia y estilo estarán presentes en cada una de las unidades de Central Park Tower, pues el diseño se efectuó de tal manera que se permitirá aprovechar al máximo todos los espacios de este fabuloso proyecto. <br><br>
  El tamaño de los condominios de Central Park Tower va desde los 78 m<sup>2</sup> hasta los 85 m<sup>2</sup>, lo cual convierte a este desarrollo en una excelente opción para generar rendimientos bajo el esquema de renta vacacional. <br><br>
  El edificio contará con excelentes amenidades tales como: Estacionamiento techado, Espacioso Roof Garden, Sala Lounge, Área de Asadores, Jacuzzi para 8 Personas, Acceso controlado, entre otras cosas. <br><br>
  Su cercanía con el Malecón te permitirá disfrutar de los mejores eventos de la ciudad tales como el Desfile del Carnaval o las actividades de la Legendaria Semana de la Moto. <br><br>
  Central Park Tower cuenta con una cercanía envidiable a las hermosas playas Mazatlecas, el Acuario, el Parque Central, el Estadio Teodoro Mariscal, Centros Comerciales, Escuelas, Hospitales, y un sin fin de lugares que podrás disfrutar a tan solo unos pasos de distancia. <br><br>
  Central Park Tower es una inversión garantizada en Mazatlán, así que no dudes en contactarnos para obtener mas información.`,
  features: [ "Cemento",
              "Prefabricado",
              "A estrenar",
              "En el corazón de la ciudad",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Aire Acondicionado",
              "Generador Eléctrico",
              "Electricidad",
              "Jacuzzi",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Tanque de Agua",
              "Vista a la Ciudad",
              "Balcón",
              "Garage",
              "Terraza",
              "Puerta Delantera de Acero",
              "Mascotas permitidas",
              "Lote en Esquina",
              "Sin Amueblar",
              "Baño en Habitación",
              "Closet Construído",
              "Tratamientos para Ventanas",
              "Barra de Desayuno",
              "Armario Empotrado",
              "Pisos de Loza",
              "Moderno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2153/r14.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  /*{id: "1079-2140",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 400000,
  zone: "Miravalles ",
  landArea: 200 , //in square meters
  description: `Excelente oportunidad para desarrollar cualquier tipo de proyecto comercial o residencial a tan solo unos pasos de una de las avenidas mas importantes y de todo Mazatlán. <br><br> 
  Este increíble terreno de 200 m<sup>2</sup> (10 metros de frente / 20 metros de largo) se ubica en una de las zonas de mayor crecimiento de toda la ciudad, lo cual sin duda lo convierte en una excelente oportunidad de inversión. <br><br>
  No dudes en contactarnos para agendar una cita y visitar esta magnifica propiedad que sin duda te encantará.`,
  features: [ "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Agua Corriente"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2140/8.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
*/
 /* {id: "1079-2139",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 400000,
  zone: "Jaripillo ",
  landArea: 200 , //in square meters
  description: `Excelente oportunidad para desarrollar cualquier tipo de proyecto comercial o residencial a tan solo unos pasos de una de las avenidas mas importantes y de todo Mazatlán. <br><br>
  Este increíble terreno de 200 m<sup>2</sup> (10 metros de frente / 20 metros de largo) se ubica en una de las zonas de mayor crecimiento de toda la ciudad, lo cual sin duda lo convierte en una excelente oportunidad de inversión. <br><br>
  No dudes en contactarnos para agendar una cita y visitar esta magnifica propiedad que sin duda te encantará.`,
  features: [ "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Zona Concurrida",
              "Zona Tranquila",
              "Agua Corriente",
              "Mascotas permitidas"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2139/8.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
*/
/* {id: "1079-2138",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 400000,
  zone: "Jaripillo ",
  landArea: 200 , //in square meters
  description: `Excelente oportunidad para desarrollar cualquier tipo de proyecto comercial o residencial a tan solo unos pasos de una de las avenidas mas importantes y de todo Mazatlán. <br><br>
  Este increíble terreno de 200 m<sup>2</sup> (10 metros de frente / 20 metros de largo) se ubica en una de las zonas de mayor crecimiento de toda la ciudad, lo cual sin duda lo convierte en una excelente oportunidad de inversión. <br><br>
  No dudes en contactarnos para agendar una cita y visitar esta magnifica propiedad que sin duda te encantará.`,
  features: [ "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Agua Corriente",
              "Mascotas permitidas"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2138/7.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
*/ 
  {id: "RL-1079-2137",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 400000,
  zone: "Jaripillo ",
  landArea: 200 , //in square meters
  description: `Excelente oportunidad para desarrollar cualquier tipo de proyecto comercial o residencial a tan solo unos pasos de una de las avenidas mas importantes y de todo Mazatlán. <br><br>
  Este increíble terreno de 200 m<sup>2</sup> (10 metros de frente / 20 metros de largo) se ubica en una de las zonas de mayor crecimiento de toda la ciudad, lo cual sin duda lo convierte en una excelente oportunidad de inversión. <br><br>
  No dudes en contactarnos para agendar una cita y visitar esta magnifica propiedad que sin duda te encantará.`,
  features: [ "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Agua Corriente"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2137/r7.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

/*  {id: "1079-2130",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 1650000,
  zone: "El Palmar ",
  bathroomsComplete: 2,
  bedrooms: 2,
  landArea: 65, //in square meters
  constructionArea: 65,
  floor: 3,
  parkingSpaces: 2,
  description: `Vivir en calma, rodeado de la belleza y serenidad que la cercanía al mar te ofrece junto a tu familia y seres queridos ahora es posible en Azul Pacifico 1798-F.
  Esta unidad ofrece 2 encantadoras recámaras, 2 baños completos, área para sala, cocina y comedor, cuarto de lavado y espacio privado de estacionamiento; lo cual garantiza una vida sin complicaciones en el mantenimiento de este hogar. <br><br>
  Este bonito condominio completamente amueblado y equipado está listo para que te mudes a él o incluso para que puedas comenzar tu negocio de rentas vacacionales, pues la ubicación estratégica y las amenidades con las que cuenta este desarrollo te permitirán sacar el máximo provecho en este tipo de negocio. <br><br>
  Azul Pacífico fue desarrollado bajo un concepto para adaptar el entorno al estilo de vida que te mereces, dando como resultado un estilo único donde podrás disfrutar de alberca, área de chapoteadero con juegos acuáticos infantiles, camastros, casa club, camas de playa, área de asadores, barra de usos múltiples, seguridad 24/7, acceso controlado, entre otras cosas. <br><br>
  Ubicado al norte de la ciudad, dentro de la zona del Nuevo Mazatlán, en donde se localizan los nuevos centros comerciales, escuelas y universidades de gran prestigio, hospitales con excelente equipamiento, restaurantes y mucho más, así que no lo pienses y agenda una cita para que conozcas esta increíble oportunidad para vivir como tú y tu familia merecen.`,
  features: [ "Cemento",
              "En perímetro de bosque",
              "Cerca de la Playa",
              "Cerca de un parque con juegos infantiles",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Cerca a Bosque",
              "Establecimiento",
              "Por el Mar",
              "Este",
              "Internet de Alta Velocidad",
              "Aire Acondicionado",
              "Portón Eléctrico",
              "Cable",
              "Electricidad",
              "Gimnasio",
              "Gas",
              "Satélite",
              "Seguridad - Vigilancia",
              "Seguridad - Alarma",
              "Bomba Hidráulica",
              "Tanque de Agua",
              "Jardín",
              "Mascotas permitidas",
              "Alberca",
              "Amueblado",
              "Cocina/Comedor pequeño",
              "Cuarto de lavado"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2130/23.jpg")
 ],
 city: "Mazatlan, Sinaloa"},
*/
 {id: "RL-1079-2125",
 relevant: false, //only six properties should have this turned on
 available: false,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1595000,
 zone: "Fraccionamiento Sábalo Country Club",
 bathroomsComplete: 1,
 bedrooms: 1,
 bathroomsHalf: 1,
 landArea: 58, //in square meters
 constructionArea: 58,
 floor: 3,
 parkingSpaces: 1,
 description: `Experimentar de una vida cerca del mar no podría ser más fácil que en esta magnifica propiedad en zona dorada. <br><br>
 Este hermoso departamento se encuentra en el 3er nivel de un reciente complejo residencial, lo cual asegurara la tranquilidad y exclusividad que tanto deseas. <br><br>
 Con un fabuloso estilo moderno - minimalista, esta propiedad cuenta con 1 amplia habitación, 1 baño, areas para sala de estar, comedor, cocina, área lavado y baño para invitados. <br><br>
 Sube a la azotea del edificio para encontrar una amplia terraza donde podrás realizar tus eventos sociales, y un jacuzzi con capacidad para 6 personas donde sin duda alguna podrás relajarte mientras admiras la belleza del área de Marina Mazatlán. <br><br>
 Sabalo Country Club es unos de los vecindarios más deseables de Mazatlán, pues es la zona turística más dinámica de toda la ciudad, donde tendrás a tu alcance los mejores restaurantes, centros comerciales, hospitales, colegios, centros nocturnos, galerías de arte, y mucho más. <br><br>
 Ya sea para vivir o como negocio de rentas vacacionales esta encantadora propiedad sin duda alguna es la oportunidad perfecta que estabas esperando, así que no lo dudes y contáctanos para conocer todas las ventajas de este fabuloso apartamento.`,
 features: [ "Cemento",
             "Prefabricado",
             "Muy céntrico",
             "Ubicación accesible",
             "Accesible al Tráfico",
             "Cerca de la Playa",
             "Autobús cercano",
             "Cerca de un parque con juegos infantiles",
             "Cerca de Zona Turística",
             "Gimnasio cercano",
             "Cerca de Campo de Golf",
             "Hospital cercano",
             "Cerca de Avenida",
             "Cerca de Parque",
             "Transporte Público Cercano",
             "Colegio cercano",
             "Tiendas cercanas",
             "Zona Tranquila",
             "Por el Mar",
             "Cerca de Centro Médico",
             "Aire Acondicionado",
             "Cable",
             "Electricidad",
             "Gas",
             "Jacuzzi",
             "Seguridad - Sistema",
             "Agua Corriente",
             "Tanque de Agua",
             "Vista a la Ciudad",
             "Garage",
             "Terraza",
             "Puerta Delantera de Acero",
             "Mascotas permitidas",
             "Amueblado",
             "Baño de servicio",
             "Cuarto de lavado",
             "Electrodomésticos Incluidos",
             "Baño en Habitación",
             "Closet Construído",
             "Tratamientos para Ventanas",
             "Armario Empotrado",
             "Pisos de Loza",
             "Moderno"  
],
 images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r1.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r2.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r3.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r4.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r5.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r6.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r7.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r8.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r9.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r10.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r11.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r12.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r13.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r14.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r15.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r16.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r17.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/r18.jpg")
],
 city: "Mazatlan, Sinaloa"},

 /*{id: "1079-2041",
 relevant: false, //only six properties should have this turned on
 available: false,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Land,
 price: 1269000,
 zone: "Boulevard Altabrisa",
 landArea: 206.89 , //in square meters
 description: `Terreno en venta en uno de los nuevos y mas exclusivos residenciales de la zona de cerritos en Mazatlán. <br><br>
 Altabrisa residencial es un fraccionamiento privado que cuenta con amplias vialidades, bonitas áreas verdes y zonas de recreación familiar con juegos infantiles, casa club con gimnasio y área de alberca privada con terraza techada, acceso controlado con vigilancia 24 horas. <br><br>
 EL terreno se encuentra a unos pasos de áreas verdes con juegos infantiles, en una zona de baja afluencia vehicular lo cual de mas seguridad a su familia, cuenta con 206 m²`,
 features: [  "Acceso fluvial",
              "En perímetro de bosque",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Densidad de Tráfico",
              "Cerca de Aeropuerto",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Hospital cercano",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Gas Natural",
              "Gimnasio",
              "Satélite",
              "Agua Corriente",
              "Alberca"
],
 images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/1.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/2.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/3.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/4.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/5.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/6.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/7.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/8.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/9.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/10.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/11.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/12.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/13.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/14.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/15.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/16.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/17.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2125/18.jpg")
],
 city: "Mazatlan, Sinaloa"},
*/
 {id: "RL-1079-2009",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 priceUsd: 150000,
 zone: "Fraccionamiento Telleria",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 99, //in square meters
 floor: 1,
 parkingSpaces: 1,
 description: `Este increíble condominio completamente amueblado y equipado cuenta con todo lo que tú necesitas para relajarte y descansar dentro o fuera de las excelentes instalaciones que ofrece La Paloma. <br><br>
 Al entrar, descubre una fabulosa y muy práctica cocina con detalles en granito y madera, que a su vez te llevan a una excelente área para comedor y sala de estar con encantadora vista hacia las áreas comunes. <br><br>
 Esta propiedad cuenta con 2 habitaciones de buen tamaño, cada una con área de closet, baño completo e iluminación natural muy buena. <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con: Espacio privado de estacionamiento, Cuarto de lavado, Área de almacenamiento y un Espaciosa terraza con espectacular vista a la Marina de Mazatlán que sin duda será del gusto de todos. <br><br>
 La Paloma es un complejo residencial con una ubicación envidiable dentro de una de las zonas de mayor crecimiento de toda la ciudad, donde podrás tener a tu alcance los mejores restaurantes, plazas comerciales, centros médicos, colegios, la playa, y muchas cosas más. <br><br>
 Ya sea que busques una propiedad como tú residencia personal o una opción para iniciar tu negocio de rentas, esta propiedad es sin duda una oportunidad ÚNICA que no te puedes perder.`,
 features: [ "Cemento",
             "Remodelado",
             "Accesible al Tráfico",
             "Densidad de Tráfico",
             "Cerca de la Playa",
             "Autobús cercano",
             "Cerca de un parque con juegos infantiles",
             "Cerca de Zona Turística",
             "Gimnasio cercano",
             "Cerca de Campo de Golf",
             "Cerca de Autopista",
             "Hospital cercano",
             "Cerca de Avenida",
             "Transporte Público Cercano",
             "Colegio cercano",
             "Tiendas cercanas",
             "Cerca a Bosque",
             "Zona Concurrida",
             "Establecimiento",
             "Por el Mar",
             "Cerca de Centro Médico",
             "Internet de Alta Velocidad",
             "Aire Acondicionado",
             "Cable",
             "Electricidad",
             "Ascensor",
             "Satélite",
             "Seguridad - Sistema",
             "Seguridad - Vigilancia",
             "Bomba Hidráulica",
             "Acceso para silla de ruedas",
             "Vista a Playa",
             "Garage",
             "Terraza",
             "Alberca",
             "Estacionamiento Cerrado",
             "Portón Eléctrico",
             "Elevador",
             "Baño en Habitación",
             "Barra de Desayuno"
],
 images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r1.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r2.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r3.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r4.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r5.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r6.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r7.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r8.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r9.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r10.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r11.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r12.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r13.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r14.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r15.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r16.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r17.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2009/r18.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-2008",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Land,
 price: 180000, // 150,000 PRECIO EN DOLARES
 zone: "La Sirena",
 landArea: 9811.5, //in square meters
 description: `Descubre la excelente oportunidad que existe en este terreno en venta en Terreno ubicado en La Sirena, con una superficie de 9,811.50 m² , ubicado en la Zona sur de Mazatlán , México. <br><br>
 La ubicación de esta propiedad es el lugar perfecto para desarrollar cualquier tipo de proyecto comercial o residencial. <br><br>
 No dudes en pedir mas información de esta fabulosa oportunidad de inversión, estamos seguros que te encantará.`,
 features: [  "Campo",
              "Cerca de Aeropuerto",
              "Autobús cercano",
              "Cerca de Avenida",
              "Colegio cercano",
              "Por el Mar"
],
 images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r1.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r2.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r3.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r4.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r5.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r6.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r7.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r8.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r9.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r10.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r11.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r12.jpg"),
           new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2008/r13.jpg")
],
 city: "Mazatlan, Sinaloa"},

 /*{id: "1079-1966",
 relevant: true, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.House,
 price: 8600000, // 150,000 PRECIO EN DOLARES
 zone: "Fraccionamiento El Cid",
 bathroomsComplete: 3,
 bedrooms: 3,
 bathroomsHalf: 2,
 landArea: 420, //in square meters
 constructionArea: 300,
 stories: 2,
 parkingSpaces: 4,
 description: `La exclusividad, estilo y confort se unen en esta fantástica residencia para ofrecerte espacios armónicos donde podrás disfrutar de las hermosas vistas a la de la marina junto a tu familia y seres queridos. <br><br>
 El impecable diseño de esta propiedad de 2 niveles permite disfrutar el colorido paisaje y aprovechar al máximo los espacios dentro de esta encantadora casa. <br><br>
 En el primer nivel encuentra generosos espacios para sala de estar y comedor con vista a la marina, cocina integral equipada con acabados de excelente calidad, cuarto de lavado, área de estudio, baño para invitados y cochera techada para 2 autos.  <br><br>
 Sube al segundo nivel por sus hermosas escaleras para encontrar 3 espaciosas habitaciones con acabados de lujo, cada una con su propio baño y closet; la recamara principal cuenta con un amplio balcón con vista a la marina. <br><br>
 Cuando no estés disfrutando de las comodidades al interior de la casa, relájate en el amplio jardin  donde se refleja la belleza natural del entorno circundante.  <br><br>
 Puerto de Malaga 1887 es una increíble propiedad que forma parte de la Marina El Cid, un vecindario de gran prestigio con una inigualable ubicación, donde tendrás prácticamente todo a tu alcance: Escuelas, Centros Comerciales, Restaurantes, Hospitales, Vida Nocturna, y la playa a tan solo unos minutos de distancia. <br><br>
 No esperes más para ser parte de este oasis residencial de lujo envolvente, y solicite más información sobre esta oportunidad única de vivir en la casa de sus sueños.`,
 features: [ "Cemento",
             "Acceso fluvial",
             "En el corazón de la ciudad",
             "Muy céntrico",
             "Ubicación accesible",
             "Accesible al Tráfico",
             "Cerca de la Playa",
             "Cerca de Zona Turística",
             "Gimnasio cercano",
             "Cerca de Campo de Golf",
             "Hospital cercano",
             "Cerca de Avenida",
             "Cerca de Parque",
             "Transporte Público Cercano",
             "Colegio cercano",
             "Tiendas cercanas",
             "Zona Tranquila",
             "Por el Mar",
             "Cerca de Centro Médico",
             "Sur",
             "Internet de Alta Velocidad",
             "Aire Acondicionado",
             "Cable",
             "Satélite",
             "Balcón",
             "Garage",
             "Jardín",
             "Desayunador",
             "Recibidor",
             "Amueblado",
             "Horno de Gas",
             "Biblioteca",
             "Baño de servicio",
             "Cuarto de juguetes",
             "Cuarto de lavado",
             "Papel Tapiz",
             "Closet Construído",
             "Moderno"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1966/28.jpg")
],
 city: "Mazatlan, Sinaloa"},
*/
  {id: "RL-1079-1933",
 relevant: true, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.House,
 price: 3950000, // 150,000 PRECIO EN DOLARES
 zone: "Fraccionamiento Soles",
 bathroomsComplete: 3,
 bedrooms: 4,
 bathroomsHalf: 1,
 landArea: 185, //in square meters
 constructionArea: 185,
 parkingSpaces: 2,
 description: `Vivir en una de las mejores zonas residenciales, cerca del mar, con la tranquilidad y privacidad que tu familia y tú se merecen ahora es posible. <br><br>
 Este increíble proyecto residencial de 2 niveles está listo para construirse y cuenta con 4 amplias habitaciones (1 en planta baja) con closets, 2 baños de finos acabados, espacio para 2 autos, y grandiosos acabados de calidad en áreas de sala, comedor y cocina para un mayor confort. <br><br>
 Coto Soles forma parte de “El Cielo Parque Residencial”, una comunidad privada de gran prestigio con las mejores amenidades para disfrutar junto a tu familia, como: Alberca, Chapoteadero, Área de Asadores, Área de Juegos Infantiles, Cancha de Basketball, Acceso Controlado, Seguridad 24/7 y mucho más. <br><br>
 Disfruta junto a tus seres queridos de una vida a tan solo unos pasos de la playa y no dudes en pedir más información de esta gran oportunidad para vivir en la casa de tus sueños.`,
 features: [  "A estrenar",
              "Acceso fluvial",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Densidad de Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Establecimiento",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Sur",
              "Internet de Alta Velocidad",
              "Cable",
              "Electricidad",
              "Gas Natural",
              "Gas",
              "Satélite",
              "Seguridad - Sistema",
              "Seguridad - Vigilancia",
              "Agua Corriente" 
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1933/r23.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-1877",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Building,
 price: 3400000, // 150,000 PRECIO EN DOLARES
 zone: "Montuosa",
 landArea: 834.2 , //in square meters
 constructionArea: 834.2 ,
 description: `Excelente edificio de 3 niveles, 3 locales comerciales en la planta baja, 2 locales en el segundo nivel y 2 locales en el tercer nivel. <br><br>
 Debido a la distribución de los niveles 2 y 3 fácilmente se pueden instalar varios consultorios en un solo local. <br><br>
 No pierdas la oportunidad de vivir de tus rentas, conoce esta increíble opción!`,
 features: [  "Cemento",
              "Para Renovarse",
              "Acceso fluvial",
              "En el corazón de la ciudad",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Densidad de Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Establecimiento",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Sur",
              "Internet de Alta Velocidad",
              "Agua Corriente",
              "Tanque de Agua",
              "Sin Amueblar",
              "Edificio Antiguo"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1877/r11.jpg")
],
 city: "Mazatlan, Sinaloa"},

 // Nuevas propieades Rafael 11 de mayo 2021

 {id: "RL-1079-2403",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1540000, 
 zone: "Fraccionamiento Real del Valle",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 75, //in square meters
 constructionArea: 75,
 parkingSpaces: 1,
 floor: 3,
 description: `Relájate y disfruta momentos agradables en este condominio completamente terminado en Real del Valle, rodeado de un ambiente tranquilo muy cerca de la marina, escuelas y tiendas de autoservicio <br><br>
 Descubre amplios espacios para sala, comedor y cocina equipada con campana y parrilla, bajo un increíble diseño y con acabados de gran calidad. <br><br>
 Esta propiedad cuenta con 2 cómodas habitaciones,  madera de cedro y triplay de caobilla y baño completo en la recamara principal <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con cuarto de lavado, Espacio privado de estacionamiento así como un tinaco y aljibe para cada condominio. <br><br>
 Zorzal Residencial es un complejo de condominios con tan solo 6 unidades, lo cual asegura la privacidad, tranquilidad y exclusividad que tú te mereces. <br><br>
 Su inmejorable ubicación cerca de la playa, hace que esta propiedad sea la opción ideal para vivir o rentar de forma anual o vacacional. <br><br>
 Si deseas experimentar la magia de invertir en esta excelente propiedad, no dudes en contactarnos para conocer esta fantástica oportunidad que no debes dejar pasar.`,
 features: [  "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Zona Tranquila",
              "Establecimiento",
              "Cerca de Centro Médico",
              "Norte",
              "Internet de Alta Velocidad",
              "Cable",
              "Gas",
              "Agua Corriente",
              "Mascotas permitidas",
              "Sin Amueblar"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2403/r8.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-2402",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1510000, 
 zone: "Fraccionamiento Real del Valle",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 75, //in square meters
 constructionArea: 75,
 parkingSpaces: 1,
 floor: 3,
 description: `Relájate y disfruta momentos agradables en este condominio completamente terminado en Real del Valle, rodeado de un ambiente tranquilo muy cerca de la marina, escuelas y tiendas de autoservicio <br><br>
 Descubre amplios espacios para sala, comedor y cocina equipada con campana y parrilla, bajo un increíble diseño y con acabados de gran calidad. <br><br>
 Esta propiedad cuenta con 2 cómodas habitaciones,  madera de cedro y triplay de caobilla y baño completo en la recamara principal <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con cuarto de lavado, Espacio privado de estacionamiento así como un tinaco y aljibe para cada condominio. <br><br>
 Zorzal Residencial es un complejo de condominios con tan solo 6 unidades, lo cual asegura la privacidad, tranquilidad y exclusividad que tú te mereces. <br><br>
 Su inmejorable ubicación cerca de la playa, hace que esta propiedad sea la opción ideal para vivir o rentar de forma anual o vacacional. <br><br>
 Si deseas experimentar la magia de invertir en esta excelente propiedad, no dudes en contactarnos para conocer esta fantástica oportunidad que no debes dejar pasar.`,
 features: [  "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Zona Tranquila",
              "Establecimiento",
              "Cerca de Centro Médico",
              "Norte",
              "Internet de Alta Velocidad",
              "Cable",
              "Gas",
              "Agua Corriente",
              "Mascotas permitidas",
              "Sin Amueblar"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2402/r10.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-2401",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1540000, 
 zone: "Fraccionamiento Real del Valle",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 75, //in square meters
 constructionArea: 75,
 parkingSpaces: 1,
 floor: 3,
 description: `Relájate y disfruta momentos agradables en este condominio completamente terminado en Real del Valle, rodeado de un ambiente tranquilo muy cerca de la marina, escuelas y tiendas de autoservicio <br><br>
 Descubre amplios espacios para sala, comedor y cocina equipada con campana y parrilla, bajo un increíble diseño y con acabados de gran calidad. <br><br>
 Esta propiedad cuenta con 2 cómodas habitaciones,  madera de cedro y triplay de caobilla y baño completo en la recamara principal <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con cuarto de lavado, Espacio privado de estacionamiento así como un tinaco y aljibe para cada condominio. <br><br>
 Zorzal Residencial es un complejo de condominios con tan solo 6 unidades, lo cual asegura la privacidad, tranquilidad y exclusividad que tú te mereces. <br><br>
 Su inmejorable ubicación cerca de la playa, hace que esta propiedad sea la opción ideal para vivir o rentar de forma anual o vacacional. <br><br>
 Si deseas experimentar la magia de invertir en esta excelente propiedad, no dudes en contactarnos para conocer esta fantástica oportunidad que no debes dejar pasar.`,
 features: [  "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Zona Tranquila",
              "Establecimiento",
              "Cerca de Centro Médico",
              "Norte",
              "Internet de Alta Velocidad",
              "Cable",
              "Gas",
              "Agua Corriente",
              "Mascotas permitidas",
              "Sin Amueblar"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2401/r8.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-2400",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1510000, 
 zone: "Fraccionamiento Real del Valle",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 75, //in square meters
 constructionArea: 75,
 parkingSpaces: 1,
 floor: 3,
 description: `Relájate y disfruta momentos agradables en este condominio completamente terminado en Real del Valle, rodeado de un ambiente tranquilo muy cerca de la marina, escuelas y tiendas de autoservicio <br><br>
 Descubre amplios espacios para sala, comedor y cocina equipada con campana y parrilla, bajo un increíble diseño y con acabados de gran calidad. <br><br>
 Esta propiedad cuenta con 2 cómodas habitaciones,  madera de cedro y triplay de caobilla y baño completo en la recamara principal <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con cuarto de lavado, Espacio privado de estacionamiento así como un tinaco y aljibe para cada condominio. <br><br>
 Zorzal Residencial es un complejo de condominios con tan solo 6 unidades, lo cual asegura la privacidad, tranquilidad y exclusividad que tú te mereces. <br><br>
 Su inmejorable ubicación cerca de la playa, hace que esta propiedad sea la opción ideal para vivir o rentar de forma anual o vacacional. <br><br>
 Si deseas experimentar la magia de invertir en esta excelente propiedad, no dudes en contactarnos para conocer esta fantástica oportunidad que no debes dejar pasar.`,
 features: [  "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Zona Tranquila",
              "Establecimiento",
              "Cerca de Centro Médico",
              "Norte",
              "Internet de Alta Velocidad",
              "Cable",
              "Gas",
              "Agua Corriente",
              "Mascotas permitidas",
              "Sin Amueblar"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2400/r10.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-2399",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1485000, 
 zone: "Fraccionamiento Real del Valle",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 75, //in square meters
 constructionArea: 75,
 parkingSpaces: 1,
 floor: 3,
 description: `Relájate y disfruta momentos agradables en este condominio completamente terminado en Real del Valle, rodeado de un ambiente tranquilo muy cerca de la marina, escuelas y tiendas de autoservicio <br><br>
 Descubre amplios espacios para sala, comedor y cocina equipada con campana y parrilla, bajo un increíble diseño y con acabados de gran calidad. <br><br>
 Esta propiedad cuenta con 2 cómodas habitaciones,  madera de cedro y triplay de caobilla y baño completo en la recamara principal <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con cuarto de lavado, Espacio privado de estacionamiento así como un tinaco y aljibe para cada condominio. <br><br>
 Zorzal Residencial es un complejo de condominios con tan solo 6 unidades, lo cual asegura la privacidad, tranquilidad y exclusividad que tú te mereces. <br><br>
 Su inmejorable ubicación cerca de la playa, hace que esta propiedad sea la opción ideal para vivir o rentar de forma anual o vacacional. <br><br>
 Si deseas experimentar la magia de invertir en esta excelente propiedad, no dudes en contactarnos para conocer esta fantástica oportunidad que no debes dejar pasar.`,
 features: [  "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Zona Tranquila",
              "Establecimiento",
              "Cerca de Centro Médico",
              "Norte",
              "Internet de Alta Velocidad",
              "Cable",
              "Gas",
              "Agua Corriente",
              "Mascotas permitidas",
              "Sin Amueblar"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2399/r10.jpg")
],
 city: "Mazatlan, Sinaloa"},

 {id: "RL-1079-2398",
 relevant: false, //only six properties should have this turned on
 available: true,
 transactionType: TransactionType.ForSale,
 propertyType: PropertyType.Department,
 price: 1485000, 
 zone: "Fraccionamiento Real del Valle",
 bathroomsComplete: 2,
 bedrooms: 2,
 landArea: 75, //in square meters
 constructionArea: 75,
 parkingSpaces: 1,
 floor: 3,
 description: `Relájate y disfruta momentos agradables en este condominio completamente terminado en Real del Valle, rodeado de un ambiente tranquilo muy cerca de la marina, escuelas y tiendas de autoservicio <br><br>
 Descubre amplios espacios para sala, comedor y cocina equipada con campana y parrilla, bajo un increíble diseño y con acabados de gran calidad. <br><br>
 Esta propiedad cuenta con 2 cómodas habitaciones,  madera de cedro y triplay de caobilla y baño completo en la recamara principal <br><br>
 Además de lo que ya hemos mencionado, esta propiedad cuenta con cuarto de lavado, Espacio privado de estacionamiento así como un tinaco y aljibe para cada condominio. <br><br>
 Zorzal Residencial es un complejo de condominios con tan solo 6 unidades, lo cual asegura la privacidad, tranquilidad y exclusividad que tú te mereces. <br><br>
 Su inmejorable ubicación cerca de la playa, hace que esta propiedad sea la opción ideal para vivir o rentar de forma anual o vacacional. <br><br>
 Si deseas experimentar la magia de invertir en esta excelente propiedad, no dudes en contactarnos para conocer esta fantástica oportunidad que no debes dejar pasar.`,
 features: [  "Cemento",
              "A estrenar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Cerca a Bosque",
              "Zona Concurrida",
              "Zona Tranquila",
              "Establecimiento",
              "Cerca de Centro Médico",
              "Norte",
              "Internet de Alta Velocidad",
              "Cable",
              "Gas",
              "Agua Corriente",
              "Mascotas permitidas",
              "Sin Amueblar"
],
 images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2398/r10.jpg")
],
 city: "Mazatlan, Sinaloa"},

   // #endregion

// #region Lucia del Carmen Abarca

/*   {id: "1079-1747",
   relevant: false, //only six properties should have this turned on
   available: false,
   transactionType: TransactionType.ForSale,
   propertyType: PropertyType.Land,
   price:   1686437, // 84,042 PRECIO EN DOLARES
   zone: "Fraccionamiento Marina Mazatlán",
   landArea: 289.8, //in square meters
   description: `Lote Residencial en Fraccionamiento Paraíso Marina en Mazatlán, Sinaloa, México. <br><br>
   En uno de los más nuevos y exclusivos fraccionamientos en la zona de Marina Mazatlán. <br><br>
   Este coto cuenta con acceso controlado y vigilancia 24/7, ambiente muy tranquilo, con sólo 227 lotes residenciales, amplias vialidades, 2 piscinas, casa club, cancha de tennis, cancha de basket ball y diversas áreas verdes.`,
   features: [  "A estrenar",
                "Ubicación accesible",
                "Cerca de Zona Turística",
                "Gimnasio cercano",
                "Cerca de Campo de Golf",
                "Hospital cercano",
                "Cerca de Avenida",
                "Transporte Público Cercano",
                "Colegio cercano",
                "Tiendas cercanas",
                "Zona Concurrida",
                "Zona Tranquila",
                "Cerca de Centro Médico",
                "Seguridad - Vigilancia",
                "Vista a la Ciudad",
                "Vista a Campo de Golf",
                "Vista Amplia",
                "Vista Parcial a Marina",
                "Cancha de Tenis",
                "Mascotas permitidas",
                "Alberca"
  ],
   images: [  new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1747/1.jpg"),
              new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1747/2.jpg"),
              new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1747/3.jpg")
  ],
   city: "Mazatlan, Sinaloa"},
*/

  {id: "LC-1079-1344",
  relevant: true, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 3980000, //  US$ 198,340 PRECIO EN DOLARES
  stories: 2,
  zone: "Sábalo Cerritos",
  bathroomsComplete: 3,
  parkingSpaces: 2,
  bedrooms: 3,
  bathroomsHalf: 1,
  constructionArea: 230, //in square meters
  landArea: 208.48, //in square meters
  description: `Casa en Venta muy cerca de la Playa en Mazatlán, Sinaloa, dentro de Altabrisa Residencial, un nuevo fraccionamiento ubicado en la zona del nuevo Mazatlán, con grandes y hermosas avenidas, bellas áreas verdes y de juegos infantiles, casa club con pool center, gimnasio perfectamente equipado, vigilancia privada 24/7 con acceso restringido. <br><br>
  Esta hermosa casa está distribuida en dos plantas: <br><br>
  Planta Baja: sala, comedor, cocina integral con barra de granito (se entrega con parrilla y campana), cuarto de servicio/lavandería, medio baño, patio, cochera muy amplia para 2 camionetas o 3 autos chicos, aljibe, cuartito de máquinas, pasillo de servicio. <br><br>
  Planta Alta: 3 recámaras con baño completo cada una -la principal con área de vestidor y terraza-; vestíbulo/área de TV. <br><br>
  Equipada con bomba hidroneumática, boiler, filtro, muebles de baño, lámparas, instalación para mini splits en toda la casa.`,
  features: [ "A estrenar",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Electricidad",
              "Gimnasio",
              "Gas",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Bomba Hidráulica",
              "Tanque de Agua",
              "Vista al Mar",
              "Vista a Playa",
              "Garage",
              "Jardín",
              "Espacio para juegos",
              "Terraza",
              "Mascotas permitidas",
              "Alberca",
              "Cocina/Comedor pequeño",
              "Cuarto de lavado",
              "Baño en Habitación",
              "Barra de Desayuno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1344/r26.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "LC-1079-860",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 1700000, //  1,700,000 | US $ 84,718 PRECIO EN DOLARES
  zone: "Centro de Mazatlán",
  constructionArea: 144.2, //in square meters
  landArea: 500, //in square meters
  description: `Magnífica oportunidad de invertir en Mazatlán teniendo hermosa vista al mar! <br><br>
  Terreno en venta de 500m<sup>2</sup> con construcción parcial en el Centro, sobre calle Morelos en las faldas del Cerro de la Nevería. Tiene una construcción inconclusa que se puede continuar o hacer un proyecto completamente nuevo. Al frente tiene una hermosa vista a la inmensidad del Océano Pacífico, por un costado hacia la ciudad. Se encuentra a sólo unos metros del malecón y del nuevo parque recreativo "Ciudades Hermanas". `,
  features: [ "Sin Terminar",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Por el Mar",
              "Vista a la Ciudad",
              "Vista al Mar",
              "Vista a Playa",
              "Vista panorámica"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-860/r17.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},

   //#endregion

// #region Liz Blancarte

  {id: "LB-1079-2361",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 799000, // MXN $ 799,000 US$ 40,192 PRECIO EN DOLARES
  zone: "Villa Florida",
  bathroomsComplete: 1,
  parkingSpaces: 1,
  bedrooms: 2,
  constructionArea: 88, //in square meters
  landArea: 96, //in square meters
  description: `Increíble precio para que adquieras una excelente opción de hogar para ti y tu familia. A tan solo unos pasos de escuelas, supermercados, plazas comerciales, hospitales, gasolineras y mucho más. <br><br>
  Esta casa de 1 nivel cuenta con 2 recamaras y un baño  y amplios espacios para sala, cocina y comedor. <br><br>
  A tan solo 5 minutos del nuevo estadio de fútbol, cerca de muchas rutas de transporte público y en un vecindario tranquilo y apacible, esta propiedad sin duda alguna será la opción perfecta para ti y tu familia. <br><br>
  No dudes en pedir más información de esta oportunidad ÚNICA que no debes dejar pasar.`,
  features: [ "Ladrillo",
              "Cemento",
              "Sin Terminar",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Gimnasio cercano",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tanque de Agua",
              "Garage",
              "Garaje con Puerta Automática"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2361/r24.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "LB-1079-2306",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 2675000, // MXN 2,675,000 US$ 134,560
  zone: "Palos Prietos",
  bathroomsComplete: 2,
  parkingSpaces: 1,
  bedrooms: 2,
  description: `Si buscas un lugar donde crear momentos únicos y disfrutar de la tranquilidad de vivir a unos pasos del mar, lbérika es la oportunidad ideal para tu inversión. <br><br>
  lbérika está a pocos pasos del malecón, se encuentra ubicada en una zona de alta plusvalía entre los lugares más emblemáticos del bello Mazatlán. Gracias a su excelente ubicación podrás disfrutar de las hermosas playas del puerto, plazas comerciales, centro histórico, vida nocturna e infinidad de ,  atracciones culturales que ofrecen sus alrededores.`,
  features: [ "A estrenar",
              "Hospital cercano",
              "Aire Acondicionado",
              "Gimnasio",
              "Ascensor",
              "Garage",
              "Pileta",
              "Elevador",
              "Moderno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2306/r10.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "LB-1079-2225",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 1150000, // MXN 1,150,000 US$ 57,848
  zone: "Fraccionamiento Villa Florida",
  bathroomsComplete: 3,
  bedrooms: 5,
  stories: 2,
  landArea: 96,
  constructionArea: 162,
  description: `Increíble oportunidad de adquirir la casa para ti y tu familia en este asombrosa ubicación, a tan solo unos pasos de escuelas, supermercados, plazas comerciales, hospitales, gasolineras y mucho más. <br><br>
  Esta casa de 2 niveles en excelentes condiciones cuenta con 5 habitaciones (2 en planta baja), 3 baños y amplios espacios para sala, cocina y comedor.  <br><br>
  A tan solo 5 minutos del nuevo estadio de fútbol, cerca de muchas rutas de transporte publico y en un vecindario tranquilo y apacible, esta propiedad sin duda alguna será la opción perfecta para ti y tu familia. <br><br>
  No dudes en pedir más información de esta oportunidad ÚNICA que no debes dejar pasar.`,
  features: [ "Cemento",
              "Paneles de Cristal",
              "Remodelado",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Gimnasio cercano",
              "Cerca de Autopista",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Agua Corriente",
              "Acceso para silla de ruedas",
              "Tanque de Agua",
              "Balcón",
              "Mascotas permitidas",
              "Pisos de Loza"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2225/r25.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "LB-1079-2110",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 2350000, // MXN 2,350,000 US$ 118,211
  zone: "Capellanes Cósala",
  landArea: 8402.76,
  description: `Si deseas construir tu casa de campo para disfrutar de tus fines de semana desconectado de todo y con la tranquilidad que tu y tu familia merecen, entonces esta es la oportunidad que estabas esperando. <br><br>
  Este asombroso terreno en el encantador pueblo mágico de Cósala tiene amplios espacios para construir tu residencia campestre en el fraccionamiento privado Rincón de Capellanes. <br><br>
  Es considerada la joya colonial del estado de Sinaloa por su ambiente recoleto, edificios novohispanos, calles empedradas, tradiciones y leyendas, gastronomía y costumbres que recuerdan con orgullo su pasado. <br><br>
  Es un lugar en el que se conjugan armónicamente la naturaleza y la cultura, la historia y el presente que, por sus inmensos valores, ha sido reconocido como uno de los Pueblos Mágicos de México. <br><br>
   Así que no dudes en pedir mas información sobre esta fantástica opción de inversión que no debes dejar pasar.`,
  features: [ "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Agua Corriente",
              "Vista a Montaña",
              "Vista panorámica",
              "Vista Amplia",
              "Mascotas permitidas"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2110/r10.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},
/*
  {id: "1079-2087",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 5466912, // MXN 5,466,912 US$ 275,000
  zone: "Fraccionamiento El Cid",
  landArea: 152.96,
  constructionArea: 152.96,
  bathroomsComplete: 2,
  bathroomsHalf: 1,
  bedrooms: 3,
  stories: 1,
  description: `Bienvenido a este espectacular condominio ubicado en uno de los vecindarios más exclusivos de todo Mazatlán donde tendrás a tu alcance todo lo necesario para vivir al máximo. <br><br>
  Este asombrosa residencia de exquisito estilo mediterráneo, te ofrece amplios espacios, excelentes acabados y una asombrosa vista al maravilloso campo de golf de El Cid que sin duda te encantará. <br><br>
  Al llegar a esta propiedad, descubre generosos espacios en la cocina con detalles de excelente calidad, sala y comedor que te llevan a un amplio balcón con vista hacia el campo de golf y las áreas comunes del vecindario. <br>v
  Encuentra también 3 increíbles recamaras, cada una con sus propios closets, y un área de balcón donde podrás tomar el sol y descansar con una encantadora vista a al vecindario y el campo de golf. <br>v
  Además de todas las fantásticas cualidades que ya hemos mencionado, esta residencia también cuenta con área de almacenamiento bastante amplia, cuarto de lavado, baño para invitados, 2 espacios de estacionamiento, entre otras cosas. <br><br>
  Con increíbles acabados y equipamiento de la mejor calidad, este condominio sin duda alguna es la opción indicada para disfrutar de todas las ventajas de una vida en Mazatlán, así que no lo dudes más y disfruta hoy en las comodidades de esta residencia única.`,
  features: [ "Cemento",
              "Paneles de Cristal",
              "Prefabricado",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Aire Acondicionado",
              "Gimnasio",
              "Ascensor",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Bomba Hidráulica",
              "Acceso para silla de ruedas",
              "Tanque de Agua",
              "Vista a Campo de Golf",
              "Vista Amplia",
              "Balcón",
              "Garage",
              "Jardín",
              "Baño de servicio",
              "Sin Amueblar",
              "Baño en Habitación",
              "Closet Construído",
              "Tratamientos para Ventanas",
              "Mediterráneo"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/28.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/29.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/30.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/31.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/32.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/33.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/34.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/35.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/36.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/37.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/38.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/39.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/40.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/41.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/42.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/43.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/44.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2087/45.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},
*/
  /* {id: "1079-2075",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 3950000, //MXN $ 3,950,000 US$ 198,695
  zone: "Cerritos al Mar",
  landArea: 207.8,
  constructionArea: 164,
  bathroomsComplete: 4,
  bedrooms: 3,
  stories: 2,
  parkingSpaces: 2,
  description: `Si deseas vivir a tan solo unos pasos de la playa, con amenidades de primer nivel y en una casa completamente nueva para ti y tu familia... entonces esta es la oportunidad que estabas buscando. <br><br>
  Esta residencia cuenta con 3 recamaras, 4 baños completos, estudio (con posibilidad de adecuarse como recamara adicional), área de sala de estar, comedor y cocina con acabados de excelente calidad. <br><br>
  Ubicado en el nuevo vecindario de Palmilla Grand Residencial, el cual cuenta con amenidades como: Alberca, Área de Asador, Casa Club, Jacuzzi, Cancha de Voleibol, Mini Golf, Acceso Controlado, Seguridad 24/7, y mucho más. <br><br>
  No te pierdas la oportunidad de adquirir esta asombrosa residencia a precio de oportunidad y contáctanos para obtener mas información, ¡Estamos seguros de que te encantará!.`,
  features: [ "Cemento",
              "Paneles de Cristal",
              "Prefabricado",
              "A estrenar",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Tranquila",
              "Por el Mar",
              "Cerca de Centro Médico",
              "Aire Acondicionado",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Acceso para silla de ruedas",
              "Vista Amplia",
              "Garage",
              "Jardín",
              "Espacio para juegos",
              "Lote en Esquina",
              "Sin Amueblar",
              "Baño en Habitación",
              "Closet Construído",
              "Tratamientos para Ventanas",
              "Moderno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/28.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/29.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/30.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/31.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/32.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/33.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/34.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/35.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/36.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/37.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/38.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/39.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/40.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/41.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/42.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2075/43.jpg")
   
 ],
  city: "Mazatlan, Sinaloa"},
/*

/*  {id: "1079-2072",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.House,
  price: 1250000, //MXN $ 1,250,000 US$ 62,878
  zone: "Fraccionamiento Lomas del Mar",
  landArea: 103.75,
  constructionArea: 141.52,
  description: `Casa en venta en Lomas del Mar, la casa ha sido pintada recientemente y se encuentra en buen estado. Su ubicación es privilegiada ya que está cerca de tiendas, escuelas, hospitales y las Avenidas principales como ejército Mexicano, ubicándola a unas cuantas cuadras del Malecón y la Playa!`,
  features: [ "Ladrillo",
              "Cemento",
              "Necesita Mejoras",
              "Sin Terminar",
              "Acceso fluvial",
              "En el corazón de la ciudad",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de Aeropuerto",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Hospital cercano",
              "Cerca de Parque",
              "Transporte Público Cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Cerca de Centro Médico",
              "Electricidad",
              "Gas Natural",
              "Gas",
              "Balcón",
              "Mascotas permitidas",
              "Estacionamiento Cerrado",
              "Sin Amueblar"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2072/25.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
*/
  {id: "LB-1079-2064",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  priceUsd: 235000,
  zone: "Fraccionamiento El Cid",
  landArea: 133,
  constructionArea: 133,
  bedrooms: 2,
  bathroomsComplete: 3,
  floor: 4,
  description: `Hermoso condominio en venta en campo de golf Tenerife Fraccionamiento El Cid <br><br>
  Hermoso condominio en cuarto nivel en Tenerife condominios en el campo de golf del Cid, con una espectacular vista frontal al campo de golf y alberca. <br><br>
  Se vende totalmente amueblado y equipado! <br><br>
  Beautiful condo for sale in El Cid's Golf Course! 4th floor of Tenerife Tower , center tower (D) facing the pool and a stunning view of the golf course. <br><br>
  Sells fully furnished and equipped!`,
  features: [ "Cemento",
              "Acceso fluvial",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Por el Mar",
              "Norte",
              "Internet de Alta Velocidad",
              "Aire Acondicionado",
              "Cable",
              "Sistema de Aire Central",
              "Gimnasio",
              "Ascensor",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Acceso para silla de ruedas",
              "Vista a Campo de Golf",
              "Vista Amplia",
              "Balcón",
              "Terraza",
              "Desayunador",
              "Amueblado",
              "Elevador",
              "Parcialmente Amueblado"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2064/r27.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
/*
  {id: "1079-2051",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 4174733, //MXN $ 4,174,733 US$ 210,000 
  zone: "Fraccionamiento El Cid",
  landArea: 131.35,
  constructionArea: 131.35,
  bedrooms: 2,
  bathroomsComplete: 3,
  floor: 2,
  parkingSpaces: 1,
  description: `Hermoso condominio en venta en campo de golf Tenerife Fraccionamiento El Cid <br><br>
  Hermoso condominio en cuarto nivel en Tenerife condominios en el campo de golf del Cid, con una espectacular vista frontal al campo de golf y alberca. <br><br>
  Se vende totalmente amueblado y equipado! <br><br>
  Beautiful condo for sale in El Cid's Golf Course! 4th floor of Tenerife Tower , center tower (D) facing the pool and a stunning view of the golf course. <br><br>
  Sells fully furnished and equipped!`,
  features: [ "Ladrillo",
              "Cemento",
              "Acceso fluvial",
              "En el corazón de la ciudad",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Por el Mar",
              "Norte",
              "Internet de Alta Velocidad",
              "Aire Acondicionado",
              "Cable",
              "Sistema de Aire Central",
              "Electricidad",
              "Gimnasio",
              "Seguridad - Vigilancia",
              "Agua Corriente",
              "Acceso para silla de ruedas",
              "Vista a Campo de Golf",
              "Vista Amplia",
              "Vista a Área Abierta",
              "Balcón",
              "Terraza",
              "Mascotas permitidas",
              "Alberca",
              "Amueblado",
              "Elevador",
              "Cuarto de lavado",
              "Electrodomésticos Incluidos",
              "Baño en Habitación",
              "Barra de Desayuno",
              "Armario Empotrado",
              "Mediterráneo",
              "Moderno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/28.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/29.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/30.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2051/31.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
*/
  {id: "LB-1079-2037",
  relevant: true, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  priceUsd: 600000,
  zone: "Sábalo Country Club",
  landArea: 200.52,
  constructionArea: 200.52,
  bedrooms: 3,
  bathroomsComplete: 3,
  floor: 15,
  parkingSpaces: 2,
  description: `Una ESPECTACULAR vista que te quitará el aliento! Desde este bello condominio totalmente amueblado y equipado con el mejor gusto y ubicado en una privilegiada zona dorada. El condominio de tres recámaras cuenta con gran amplitud , iluminación y todas las comodidades. Se encuentra en el piso 15 de la Torre en esquina, lo cuál permite disfrutar de una vista increíble ubicada en esquina para disfrutar de toda la Bahía y la ciudad, tanto de día como de noche con la iluminación de Mazatlán podrás tener el mejor atardecer!  Las amenidades de Gavias Golden Shores son excelentes! Cine, seguridad, lobby, Mesanine, sala de eventos, Gym, Spa, área de negocios, jaccuzzis, alberca y mas!  GAVIAS GOLDEN SHORES es sin duda una de las mejores opciones luxury a pie de playa en todo el puerto! Además de todo esto, el condominio incluye 2 cajones de estacionamiento escriturados y una bodega particular. <br><br>
  A  stunning view that will  take your breath away! This spectacular three bedroom condo sells fully furnished and equipped with the best taste in decor and located in the privileged Golden Zone. The condo is quite spacious, very well illuminated and has all the commodities. This property on the 15th floor is a corner unit, which gives you the most incredible view to enjoy the entire Bay during the day and to enjoy the most gorgeous sunsets!  The ammenties at Gavias Golden Shores include: Movie theater, security 24/7, lobby, game room, gym, spa, room for events, four hot tubs, a huge swimming pool and more!  Golden Shores is without a doubt one of the best luxury beachfront options in the entire city!  On top of this, the property includes TWO parking spaces and a storage room.`,
  features: [ "Cemento",
              "Acceso fluvial",
              "En el corazón de la ciudad",
              "Muy céntrico",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Por el Mar",
              "Norte",
              "Internet de Alta Velocidad",
              "Aire Acondicionado",
              "Cable",
              "Electricidad",
              "Gimnasio",
              "Gas",
              "Ascensor",
              "Jacuzzi",
              "Seguridad - Sistema",
              "Seguridad - Vigilancia",
              "Seguridad - Alarma",
              "Salida Emergencia",
              "Agua Corriente",
              "Acceso para silla de ruedas",
              "Vista a la Ciudad",
              "Vista al Mar",
              "Vista a Playa",
              "Vista panorámica",
              "Vista Amplia",
              "Balcón",
              "Terraza",
              "Alberca",
              "Estacionamiento Cerrado",
              "Amueblado",
              "Elevador",
              "Cuarto de lavado",
              "Electrodomésticos Incluidos",
              "Baño en Habitación",
              "Closet Construído",
              "Barra de Desayuno",
              "Contemporáneo",
              "Moderno"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r28.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r29.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r30.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r31.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r32.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r33.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r34.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r35.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r36.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r37.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r38.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r39.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r40.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r41.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r42.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r43.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r44.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r45.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r46.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r47.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r48.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2037/r49.jpg")
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "LB-1079-2028",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Building,
  price: 4200000, // MXN $ 4,200,000 US$ 211,271
  zone: "Juan Carrasco",
  landArea: 169,
  constructionArea: 492,
  description: `Propiedad comercial en venta, ubicada sobre avenida Juan Carrasco. <br><br>
  Consta de 3 niveles , que por su distribución destaca el amplio potencial en alta rentabilidad comercial y habitacional ya que cuenta en planta principal con local comercial, en segunda planta, área de oficinas , y en tercera planta, cuenta con dos departamentos de 2 recámaras con baño completo cada uno.`,
  features: [ "Aire Acondicionado"],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2028/r17.jpg")          
 ],
  city: "Mazatlan, Sinaloa"},

  {id: "LB-1079-2014",
  relevant: false, //only six properties should have this turned on
  available: true,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 3785600, // MXN $ 3,785,600 US$ 190,426
  zone: "Fraccionamiento Puerta al Mar",
  landArea: 560,
  description: `Excelente terreno en venta en Puerta al Mar!  Uno de los mejores Fraccionamientos privados de Mazatlán! <br><br>
  Lot for sale in one of the best and most exclusive and private complex, Puerta Al Mar in the Marina has wonderful amenities than will provide you with the best quality of life!`,
  features: [ "Acceso fluvial",
              "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de un parque con juegos infantiles",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Transporte Público Cercano",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Cerca de Centro Médico",
              "Norte",
              "Seguridad - Vigilancia",
              "Espacio para juegos"
],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2014/r24.jpg")          
 ],
  city: "Mazatlan, Sinaloa"},
/*
  {id: "1079-2014",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Land,
  price: 1790000, // MXN $ 1,790,000 US$ 90,042
  zone: "Fraccionamiento Arroyos de la Marina",
  landArea: 348.43,
  description: `Gran oportunidad para invertir o construir , en este excelente lote dentro del Fracc. Arroyos de la Marina, coto privado en una de la zonas con mayor plusvalía en Marina Mazatlán.  Cuenta con seguridad 24 horas, acceso controlado, canchas de basketball, tenis , salón de eventos y una alberca hermosa. <br><br>
  Arroyos de la Marina tiene una ubicación privilegiada cerca de Galerías, centro de convenciones, Liverpool, Cinemex, Sam´s Club y de las escuelas más importantes de la ciudad.`,
  features: [ "Ubicación accesible",
              "Accesible al Tráfico",
              "Cerca de la Playa",
              "Autobús cercano",
              "Cerca de Zona Turística",
              "Gimnasio cercano",
              "Cerca de Campo de Golf",
              "Hospital cercano",
              "Cerca de Avenida",
              "Cerca de Parque",
              "Estación de tren cerca",
              "Colegio cercano",
              "Tiendas cercanas",
              "Zona Concurrida",
              "Zona Tranquila",
              "Cerca de Centro Médico",
              "Norte",
              "Seguridad - Vigilancia",
              "Pileta",
              "Lote en Esquina"
],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1989/23.jpg")      
 ],
  city: "Mazatlan, Sinaloa"},
*/
 /* {id: "1079-1961",
  relevant: false, //only six properties should have this turned on
  available: false,
  transactionType: TransactionType.ForSale,
  propertyType: PropertyType.Department,
  price: 11927807, //MXN 11,927,807 US$ 600,000 
  zone: "Fraccionamiento Marina Garden",
  landArea: 91.17 ,
  constructionArea: 91.17 ,
  bedrooms: 2,
  bathroomsComplete: 2,
  parkingSpaces: 1,
  description: `ENGLISH BELOW ----
  Marina Garden Villas es un condominio de dos recámaras y dos baños completos, se vende completamente amueblado y equipado ! También con lavadora y secadora. <br><br>
  MGV es un lugar con muy buena reputación. Seguridad 24/7 , elevador, estacionamiento cerrado con acceso controlado, alberca grande y asador. <br><br>
  Tiene excelente demanda de renta tanto Nacional como extranjeros y si es para vivir también es una excelente opción ya que es muy tranquilo. <br><br>
  El mantenimiento mensual es $2,000 pesos. <br><br>
  Ubicado en piso 5 lo cual le brinda mayor privacidad, el condominio cuenta con dos balcones, uno en la sala y otro en la recámara principal que le permitirá disfrutar de la vista hacia la alberca así como una vista panorámica y parcial al mar. <br><br>
  No te lo puedes perder!!!  <br><br>
  ----------------------------------------------------------------------------------------------- <br><br>
  Marina Garden Villas is a 2 bedroom, 2 full bath condo, sells fully furnished and equipped! Even with washer&dryer. <br><br>
  MGV is a place with very good reputation. Security 24/7 , elevator, closed parking lot with controlled access, a very spacious pool and bbq grill area. <br><br>
  HOA is only $2000 pesos per month! <br><br>
  The condo is located on the 5th floor which gives you more privacy , condo has two terraces, one in the living room area and one in the master bedroom , to enjoy a beatutil pool view and a panoramic and partial view to the Ocean. `,
  features: [ "Aire Acondicionado",
              "Vista panorámica",
              "Pileta",
              "Amueblado",
              "Elevador"
 ],
  images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/1.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/2.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/3.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/4.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/5.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/6.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/7.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/8.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/9.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/10.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/11.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/12.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/13.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/14.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/15.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/16.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/17.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/18.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/19.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/20.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/21.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/22.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/23.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/24.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/25.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/26.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/27.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/28.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/29.jpg"),
            new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1961/30.jpg")
 ],
  city: "Mazatlan, Sinaloa"},
*/
//#endregion

// #region Armando Gutiérrez Zerecero

{id: "AG-1079-2369",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Department,
priceUsd: 159800,
zone: "Marina del Sol",
constructionArea: 138.34,
bedrooms: 2,
bathroomsComplete: 3,
parkingSpaces: 1,
stories: 1,
floor: 2,
description: `Esta es tu oportunidad de vivir frente a la playa en una comunidad cerrada de alta plusvalía. Este condominio se encuentra en segunda planta, muy cerca de la alberca y con una extraordinaria vista al mar, además fue remodelado por lo que sus interiores son modernos y se encuentran en excelente condiciones. <br><br>
Tiene una superficie de 138 mt2 y cuenta con sala-comedor, cocina integral, amplia terraza, 2 amplias recamaras y cuarto de lavado. La recamara principal cuenta con acceso a terraza y closet empotrado. Anímate a conocer Marina del Sol y enamórate del estilo de vida Mazatleco.`,
features: [ "Cemento",
            "Remodelado",
            "Acceso fluvial",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Cerca de Zona Turística",
            "Cerca de Campo de Golf",
            "Hospital cercano",
            "Tiendas cercanas",
            "Zona Tranquila",
            "Por el Mar",
            "Cerca de Centro Médico",
            "Norte",
            "Internet de Alta Velocidad",
            "Aire Acondicionado",
            "Cable",
            "Seguridad - Vigilancia",
            "Vista al Mar",
            "Garage",
            "Desayunador",
            "Amueblado",
            "Parcialmente Amueblado",
            "Cuarto de lavado",
            "Electrodomésticos Incluidos",
            "Baño en Habitación",
            "Moderno"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2369/r14.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2366",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
price: 2750000, //MXN $ 2,750,000 US$ 135,680
zone: "Fraccionamiento Real del Valle",
constructionArea: 179,
landArea: 112,
bedrooms: 2,
bathroomsComplete: 3,
parkingSpaces: 2,
stories: 2,
description: `Conoce esta excelente casa ubicada en Real del Valle Coto 15 frente a la alberca, esta privada tiene amenidades como alberca, casa club, juegos infantiles y cancha de futbol. Además de lo mencionado, el coto cuenta con acceso controlado y seguridad 24 horas para tu tranquilidad. <br><br>
La casa esta distribuida de la siguiente manera: en planta baja se encuentra estacionamiento techado para dos autos, sala-comedor, cocina integral con terminaciones de granito, espacio para recamara, un baño completo y patio; en la planta alta se encuentran la recamara principal con terraza y baño completo, otra recamara con baño completo y cuarto de lavado. Toda la casa tiene terminaciones de primera. <br><br>
Vive en una zona de alta plusvalía, cerca de centros comerciales y a 10 minutos de la playa.`,
features: [ "Ladrillo",
            "Cemento",
            "Madera",
            "A estrenar",
            "Acceso fluvial",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Cerca de un parque con juegos infantiles",
            "Cerca de Zona Turística",
            "Gimnasio cercano",
            "Hospital cercano",
            "Cerca de Parque",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Zona Tranquila",
            "Norte",
            "Electricidad",
            "Seguridad - Vigilancia",
            "Vista a Montaña",
            "Vista panorámica",
            "Vista a Área Abierta",
            "Balcón",
            "Espacio para juegos",
            "Terraza",
            "Mascotas permitidas"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2366/r16.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2333",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
priceUsd: 320000,
zone: "Fraccionamiento El Cid",
constructionArea: 303,
landArea: 432.12,
bedrooms: 4,
bathroomsComplete: 3,
bathroomsHalf: 1,
parkingSpaces: 3,
stories: 2,
description: `Excelente opción en fraccionamiento EL CID con hermosa vista al campo de golf y ubicada estratégicamente a unos minutos de una de las mejores playas de Mazatlán , con amplio garage  hasta para 3 autos, sala comedor cocina con un amplio jardín , 3 recamaras en segundo piso y una cuarta  recamara en 3er piso,  terreno de 432 metros cuadrados y 302 de construcción.`,
features: [ "Ladrillo",
            "Cemento",
            "Mosaico",
            "Necesita Mejoras",
            "Para Renovarse",
            "En el corazón de la ciudad",
            "Cerca de la Playa",
            "Cerca de Zona Turística",
            "Cerca de Campo de Golf",
            "Hospital cercano",
            "Tiendas cercanas",
            "Zona Tranquila",
            "Cerca de Centro Médico",
            "Norte",
            "Internet de Alta Velocidad",
            "Aire Acondicionado",
            "Cable",
            "Seguridad - Sistema",
            "Seguridad - Vigilancia",
            "Seguridad - Alarma",
            "Agua Corriente",
            "Vista a Campo de Golf",
            "Vista Amplia",
            "Balcón",
            "Garage",
            "Jardín",
            "Desayunador",
            "Recibidor",
            "Amueblado",
            "Cuarto de lavado",
            "Electrodomésticos Incluidos",
            "Baño en Habitación",
            "Closet Construído",
            "Edificio Antiguo"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2333/r23.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2305",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Av. Mariano Huerta",
landArea: 103037,
description: `Excelente terreno para inversión junto a la Fabrica de Drones Mazatlán, tiene una superficie de 10 Hectáreas y cuenta con camino de acceso desde Av. Mariano Huerta. Además esta en una ubicación privilegiada debido a su cercanía al Parque Aeroespacial, fabrica de drones y área de Cerritos Mazatlán.`,
features: [ "Acceso fluvial",
            "Campo",
            "Cerca de Autopista",
            "Cerca de Avenida",
            "Zona Tranquila",
            "Agua Corriente",
            "Vista Amplia"

],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2305/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2305/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2305/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2305/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2305/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2305/r6.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2285",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
zone: "La Paloma",
priceUsd: 118000,
constructionArea: 80,
landArea: 80,
bedrooms: 1,
bathroomsComplete: 1,
parkingSpaces: 1,
stories: 1,
description: `Excelente condominio en La Paloma, ubicado en Malecón. Este departamento amueblado de 1 recamara y 1 baño, sala-comedor, cocina integral, un espacio de estacionamiento tiene increíbles amenidades como alberca templada, estacionamiento privado y techado, área verde, palapa, seguridad y acceso controlado.`,
features: [ "Cemento",
            "Madera",
            "Para Renovarse",
            "Portero",
            "Acceso fluvial",
            "En el corazón de la ciudad",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Autobús cercano",
            "Cerca de Zona Turística",
            "Cerca de Avenida",
            "Por el Mar",
            "Cerca de Centro Médico",
            "Suroeste",
            "Aire Acondicionado",
            "Portón Eléctrico",
            "Ascensor",
            "Seguridad - Vigilancia",
            "Seguridad - Alarma",
            "Agua Corriente",
            "Acceso para silla de ruedas",
            "Vista a la Ciudad",
            "Vista al Mar",
            "Vista a Playa",
            "Vista al Lago",
            "Jardín",
            "Terraza",
            "Puerta Delantera de Acero",
            "Alberca",
            "Estacionamiento Cerrado",
            "Portón Eléctrico",
            "Amueblado",
            "Elevador",
            "Closet Construído",
            "Barra de Desayuno",
            "Edificio Antiguo"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2285/r31.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2231",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Av. Paseo Pacifico",
price: 3390000, // MXN $ 3,390,000 US$ 167,256
landArea: 300,
description: `Excelente oportunidad de terreno en Av. Paseo Pacifico en el Fracc. Marina Universidad, con 10 mt de pie de carretera. Tiene una superficie de 300 mt2 y tiene una excelente ubicación debido a su gran visibilidad desde Av. Oscar Perez Escobosa. El terreno es una excelente oportunidad de inversión por  la cantidad de desarrollos en construcción que se encuentran en Av. Paseo Pacifico, tales como La Pedrera, Sonterra y Pacifico Hills.`,
features: [ "Acceso fluvial",
            "Campo",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Densidad de Tráfico",
            "Cerca de Zona Turística",
            "Cerca de Autopista",
            "Hospital cercano",
            "Cerca de Avenida",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Cerca de Centro Médico",
            "Norte",
            "Vista a Montaña",
            "Vista a Área Abierta"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2231/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2231/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2231/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2231/r4.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2220",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Av. Del Delfín",
price:  3350000, // MXN $ 3,350,000 US$ 165,282
landArea: 628.81 ,
description: `Terreno en avenida Del Delfín, a un lado de Chulavista. Muy versátil para comercio, departamentos, bodegas, etc, tiene una superficie de 628.81 mt2 y se encuentra a menos de 500 mt de Av. Bicentenario. <br><br>
Invierte hoy en Mazatlán. <br><br>
KWID 1079-2220`,
features: [ "Accesible al Tráfico",
            "Autobús cercano",
            "Cerca de un parque con juegos infantiles",
            "Hospital cercano",
            "Cerca de Avenida",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Cerca de Centro Médico",
            "Noreste",
            "Vista a Área Abierta",
            "Lote en Esquina"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2220/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2220/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2220/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2220/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2220/r5.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-2118",
relevant: true, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Autopista Mazatlán-Cualiacan",
price:   18310000, // MXN $ 18,310,000 US$ 903,379
landArea: 45783,
description: `Conoce este terreno ubicado en Predio Enramada y Tuliman, su extensión es de 4.57 HAS y cuenta con 119 mt de pie de carretera, casi frente a gasolinera Red Petroil. Tiene una ubicación estratégica en carretera Autopista Mazatlán-Culiacán, se encuentra al lado de Desarrollo Puertas De Mazatlán y a menos de 2 KM de la playa. <br><br>
Se vende el terreno completo o en fracciones. Invierte en Mazatlán.`,
features: [ "Necesita ser reconstruído",
            "Acceso fluvial",
            "Campo",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Cerca de Autopista",
            "Por el Mar",
            "Noreste"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2118/r8.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-1991",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
zone: "LOS MANGOS I REMODELADA",
price: 1725000, //MXN $ 1,725,000 US$ 85,108
constructionArea: 93.5,
landArea: 117.44,
bedrooms: 3,
bathroomsComplete: 1,
bathroomsHalf: 1,
parkingSpaces: 2,
stories: 2,
description: `Casa en venta en Fracc. Los Mangos  con 126 mt2 de terreno y 93.5 mt2 de construcción. La casa esta remodelada y cuenta con espacio de estacionamiento para dos autos, sala, comedor, cocina integral equipada, medio baño en planta baja, pequeño patio, 3 recamaras y 1 baño en planta alta. <br><br>
El fraccionamiento cuenta con seguridad 24 hrs, acceso controlado, áreas verdes y de juegos infantiles.`,
features: [ "Cemento",
            "Remodelado",
            "Acceso fluvial",
            "Muy céntrico",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Autobús cercano",
            "Cerca de un parque con juegos infantiles",
            "Gimnasio cercano",
            "Hospital cercano",
            "Cerca de Avenida",
            "Transporte Público Cercano",
            "Norte",
            "Aire Acondicionado",
            "Seguridad - Vigilancia",
            "Pileta",
            "Baño en Habitación",
            "Barra de Desayuno",
            "Armario Empotrado",
            "Contemporáneo"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1991/r11.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-1945",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Enramada y Tuliman",
price:   1590000, // MXN $ 1,590,000 US$ 78,447
landArea: 500,
description: `Excelente oportunidad de inversión para casa de playa en Mazatlán, Sinaloa. Este terreno de 500 mt2 esta ubicado en el km 16 antes de caseta de Mármol, es un polígono de 10 x 50 mt con diez metros de pie de playa virgen y acceso por carretera. <br><br>
Atrévete a invertir en la playa y disfruta de todo lo que tiene Mazatlán.`,
features: [ "Cerca de la Playa2",
            "Cerca de Autopista2",
            "Zona Tranquila2",
            "Por el Mar2",
            "Norte2",
            "Vista al Mar2",
            "Vista panorámica2"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1945/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1945/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1945/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1945/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1945/r5.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-1639",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Marina Mazatlán",
price: 1690000, // MXN $ 1,690,000 US$ 83,381
landArea: 358,
description: `Excelente terreno para invertir en fracc. Marina Universidad frente a Plaza Galerías en Avenida Paseo del Pacífico y Oscar Pérez Escobosa, conecta con las principales plazas comerciales, centros y fraccionamientos así como punto de inversión en urbanizaciones en construcción los últimos 3 años. Aproveche esta oportunidad de inversión para iniciar su negocio u obtener excelentes alquileres.`,
features: [ "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Zona Tranquila",
            "Norte",
            "Vista a la Ciudad",
            "Vista al Mar",
            "Vista a Montaña",
            "Vista panorámica"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1639/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1639/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1639/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1639/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1639/r5.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-1508",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
zone: " Fraccionamiento Lomas de Mazatlán",
price: 14400000, //MXN $ 16,400,000 US$ 809,143
constructionArea: 703.42,
landArea: 689.11,
bedrooms: 5,
bathroomsComplete: 5,
parkingSpaces: 8,
stories: 3,
description: `Excelente condominio en La Paloma, ubicado en Malecón. Este departamento amueblado de 1 recamara y 1 baño, sala-comedor, cocina integral, un espacio de estacionamiento tiene increíbles amenidades como alberca templada, estacionamiento privado y techado, área verde, palapa, seguridad y acceso controlado.`,
features: [ "Cemento",
            "Madera",
            "Para Renovarse",
            "Portero",
            "Acceso fluvial",
            "En el corazón de la ciudad",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Autobús cercano",
            "Cerca de Zona Turística",
            "Cerca de Avenida",
            "Por el Mar",
            "Cerca de Centro Médico",
            "Suroeste",
            "Aire Acondicionado",
            "Portón Eléctrico",
            "Ascensor",
            "Seguridad - Vigilancia",
            "Seguridad - Alarma",
            "Agua Corriente",
            "Acceso para silla de ruedas",
            "Vista a la Ciudad",
            "Vista al Mar",
            "Vista a Playa",
            "Vista al Lago",
            "Jardín",
            "Terraza",
            "Puerta Delantera de Acero",
            "Alberca",
            "Estacionamiento Cerrado",
            "Portón Eléctrico",
            "Amueblado",
            "Elevador",
            "Closet Construído",
            "Barra de Desayuno",
            "Edificio Antiguo"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1508/r36.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-1319",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "CARR. CERRITOS-EL HABAL",
landArea: 30000,
description: `Increíble oportunidad de Inversión ubicada en Carretera Cerritos-El Habal. Cuenta con 3 HAS de extensión y tiene 102 mt de pie de carretera. A unos minutos de El Habal y a 15 min de Mazatlán. <br><br>
$35 USD x m<sup>2</sup>`,
features: [ "Acceso fluvial",
            "Campo",
            "En perímetro de bosque",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de Autopista",
            "Norte",
            "Noroeste",
            "Electricidad",
            "Agua Corriente",
            "Vista panorámica",
            "Vista Amplia"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1319/r9.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-1101",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
zone: "Fraccionamiento El CID",
price: 5800000, //MXN $ 5,800,000 US$ 286,474
constructionArea: 342.6,
landArea: 262.57,
bedrooms: 3,
bathroomsComplete: 2,
bathroomsHalf: 1,
description: `Hermosa casa completamente remodelada, cuenta con: 3 recamaras cada una con closets de cedro, 2 y medio baños con vidrios templados, en planta baja sala comedor y cocina completamente iluminada con vista a jardín frontal, un cuarto en planta baja para estudio o posible recamara, estacionamiento para dos autos, cuarto de servicio, alberca común, adicional muy cerca a la entrada del fraccionamiento.`,
features: [ "Cemento",
            "Remodelado",
            "Acceso fluvial",
            "En el corazón de la ciudad",
            "Muy céntrico",
            "Cerca de la Playa",
            "Cerca de Zona Turística",
            "Hospital cercano",
            "Zona Tranquila",
            "Norte",
            "Aire Acondicionado",
            "Garage",
            "Jardín",
            "Cancha de Tenis",
            "Amueblado",
            "Cuarto de lavado"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-1101/r30.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "AG-1079-508",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Land,
zone: "Fraccionamiento Marina Universidad",
landArea: 611,
description: `TERRENO DE 20 X 30.5 UBICADO EN EXCELENTE ZONA TANTO COMERCIAL O HABITACIONAL , CERCA DE PLAZA GALERIAS A 30 MTS DE AV PECHE RICE Y MUY CERCA DE REAL DEL VALLE DE ESCUELAS DE TIENDAS DE AUTOSERVICIOS HOTELES FRACCIONAMIENTOS NUEVOS ETC.  CON UN POTENCIAL COMERCIAL MUY IMPORTANTE... UNA EXCELENTE INVERSIÓN CON IMPORTANTE PLUSVALÍA.`,
features: [ "Acceso fluvial",
            "Campo",
            "En perímetro de bosque",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de Autopista",
            "Norte",
            "Noroeste",
            "Electricidad",
            "Agua Corriente",
            "Vista panorámica",
            "Vista Amplia"
],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/ 1079-508/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/ 1079-508/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/ 1079-508/r3.jpg")
],
city: "Mazatlan, Sinaloa"},


//#endregion

// #region Gerardo Torres (Jerry)

{id: "GT-1079-2229",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
price: 1650000, // MXN $ 1,650,000 US$ 81,497
zone: "Fraccionamiento Real Pacífico",
stories: 2,
bedrooms: 2,
bathroomsComplete: 2,
bathroomsHalf: 1,
parkingSpaces: 2,
floor: 2,
constructionArea: 77.48, //in square meters
landArea: 107.79, //in square meters
description: `Hermosa casa en fraccionamiento real pacifico que consta de 2 plantas, cochera con portón de reja para dos autos, cuenta con dos recamaras, 2 baños y medio, sala, comedor, cocina y patio de servicio, se encuentra en su ubicación accesible a tiendas, parada de camiones, a unos minutos de centros comerciales, como plaza Acaya y Galerias Mazatlán. <br><br>
No pierdas la oportunidad de conocerla, contáctanos nos encontramos a sus ordenes¡¡`,
features: [ "Cemento",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Autobús cercano",
            "Tiendas cercanas",
            "Aire Acondicionado",
            "Electricidad",
            "Gas",
            "Agua Corriente",
            "Vista a la Ciudad",
            "Mascotas permitidas",
            "Estacionamiento Cerrado",
            "Recibidor",
            "Cocina/Comedor pequeño",
            "Closet Construído",
            "Barra de Desayuno",
            "Moderno"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r36.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r37.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r38.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2229/r39.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "GT-1079-2228",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
price: 1290000, // MXN $ 1,290,000 US$ 63,716
zone: "Fraccionamiento Real Pacífico",
stories: 1,
floor: 1,
bedrooms: 3,
bathroomsComplete: 1,
parkingSpaces: 1,
constructionArea: 63, //in square meters
landArea: 107.25, //in square meters
description: `Hermosa casa en el fraccionamiento de mejor ubicación urbana de la zona, fraccionamiento Real Pacífico, a solo unos minutos de centros comerciales, como son Plaza Acaya y Galerías Mazatlán, se encuentra cerca de un jardín, y múltiples opciones de comercios, accesible a la parada de camión, así como tiendas. <br><br>
Casa compuesta de acceso recientemente remodelada en su fachada, lo que la hace distinta a las demás casas, con un diseño moderno y con palmeras que le dan un aire de frescura y distinción, sala, comedor y una cocina equipada con cocineta, estufa y barra, moderna muy bonita, es de una sola planta, un patio de servicio con lavadero y en la parte posterior de la casa tiene un espacioso patio trasero.  <br><br>
No pierdas la oportunidad de conocer esta hermosa casa ubicada en Real Pacífico, llámanos y haz tu cita!!`,
features: [ "Cemento",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Autobús cercano",
            "Cerca de un parque con juegos infantiles",
            "Oeste",
            "Agua Corriente",
            "Tanque de Agua",
            "Cocina/Comedor pequeño",
            "Sin Amueblar",
            "Moderno"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2228/r30.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "GT-1079-2226",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
price: 1290000, // MXN $ 1,290,000 US$ 63,716
zone: "Fraccionamiento Real Pacífico",
stories: 1,
floor: 1,
bedrooms: 3,
bathroomsComplete: 1,
parkingSpaces: 1,
constructionArea: 63.54, //in square meters
landArea: 107.25, //in square meters
description: `Hermosa casa en el fraccionamiento de mejor ubicación urbana de la zona, fraccionamiento Real Pacífico, a solo unos minutos de centros comerciales, como son Plaza Acaya y Galerías Mazatlán, se encuentra cerca de un jardín, y múltiples opciones de comercios, accesible a la parada de camión, así como tiendas. <br><br>
Casa compuesta de acceso recientemente remodelada en su fachada, lo que la hace distinta a las demás casas, con un diseño moderno y con palmeras que le dan un aire de frescura y distinción, sala, comedor y una cocina equipada con cocineta, estufa y barra, moderna muy bonita, es de una sola planta, un patio de servicio con lavadero y en la parte posterior de la casa tiene un espacioso patio trasero. <br><br>
No pierdas la oportunidad de conocer esta hermosa casa ubicada en Real Pacífico, llámanos y haz tu cita!!`,
features: [ "Cemento",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Autobús cercano",
            "Cerca de un parque con juegos infantiles",
            "Oeste",
            "Electricidad",
            "Gas",
            "Agua Corriente",
            "Tanque de Agua",
            "Vista a la Ciudad",
            "Mascotas permitidas",
            "Cocina/Comedor pequeño",
            "Sin Amueblar",
            "Cuarto de lavado",
            "Recámara Planta Baja",
            "Moderno"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2226/r30.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "GT-1079-2216",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForRent,
propertyType: PropertyType.House,
price: 11000, // MXN $ 11,000 US$ 543
zone: "Fraccionamiento Real del Valle",
stories: 2,
bedrooms: 4,
bathroomsComplete: 2,
bathroomsHalf: 1,
parkingSpaces: 2,
constructionArea: 104, //in square meters
landArea: 172.51, //in square meters
description: `CASA AMUEBLADA en el Fraccionamiento de mejor ubicación, Real del Valle, siendo el predilecto por los habitantes del Puerto de Mazatlán, por gozar de una localización estratégica, ademas de estar rodeada de hermosos conjuntos residenciales o privadas, o mejor conocidos como Cotos, siendo un conjunto de casas en un ambiente sano, familiar, buen entorno y vecinos, esta casa se encuentra ubicada en el Coto 9 que es conocido por ser uno de los mas armoniosos. <br><br>
La casa cuenta con acceso a la alberca del fraccionamiento, seguridad las 24 horas y acceso controlado. <br><br>
Distribución de la casa: <br><br>
PLANTA BAJA <br><br>
Cochera para dos autos, acceso a la casa donde se encuentra medio baño en la entrada, amueblada con una sala 3,2,1 y mesa de centro, debajo de la escalera se encuentra un espacio para almacenamiento con puerta, un comedor de madera de 6 plazas, y un espacio tipo estudio, que bien puede ser utilizado como oficina o una recámara más (cuarta recámara), enseguida, una barra para desayunar, y cocina integral, equipada, salida a un patio de servicio donde puedes colocar tu lavadora y secadora, con ventilación abierta <br><br>
PLANTA ALTA <br><br>
Subiendo las escaleras, muy iluminadas, se encuentra un baño completo para las dos recamaras secundarias, y en su habitación principal con un baño completo, vista hacia el frente de la casa, todas cuentan con closet tipo puerta corrediza, aires acondicionados, ropa de cama, escritorio pequeño para home office o tareas. <br><br>
Llámenos para agendar su cita para conocer esta casa donde vivirá cómodamente rodeado de un entorno familiar, seguro en esta espaciosa e iluminada casa amueblada en la mejor zona de familias de nivel adquisitivo intermedio, superior, ambiente de familias y niños rodeado de escuelas, colegios privados, guarderías,  tiendas de conveniencia, lavandería, papelerías, salon de belleza, doctor, farmacias, parada de autobuses, y muchas cosas más que hacen esta casa su mejor opción.`,
features: [ "Cemento",
            "Accesible al Tráfico",
            "Autobús cercano",
            "Gimnasio cercano",
            "Cerca de Avenida",
            "Colegio cercano",
            "Tiendas cercanas",
            "Zona Concurrida",
            "Zona Tranquila",
            "Cerca de Centro Médico",
            "Aire Acondicionado",
            "Seguridad - Vigilancia",
            "Bomba Hidráulica",
            "Vista a la Ciudad",
            "Mascotas NO permitidas",
            "Alberca",
            "Baño en Habitación",
            "Barra de Desayuno",
            "Moderno"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r36.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r37.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2216/r38.jpg")
],
city: "Mazatlan, Sinaloa"},
/*
{id: "1079-2208",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
price: 4980000, // MXN $ 4,980,000 US$ 245,972
zone: "Fraccionamiento Mediterráneo Club Residencial",
stories: 2,
bedrooms: 3,
bathroomsComplete: 2,
bathroomsHalf: 1,
parkingSpaces: 2,
constructionArea: 229.24, //in square meters
landArea: 166.3, //in square meters
description: `Casa de dos plantas 3 recamaras, 2.5 baños muy amplia, cuenta con una cochera techada espaciosa,  y encima de la cochera, en la habitación principal, cuenta con una  amplia terraza, hermosamente situada en una esquina, modernos detalles arquitectónicos, y de iluminación. En el área social,  cuenta con un acabado en el techo de luz indirecta, ventanas amplias que brindan mucha iluminación, techos de doble altura (3.30 mts) pisos de porcelanato de 60x60, closets de puertas corredizas tipo espejo, su cocina fue rediseñada para tener mucho espacio de almacenaje, cubierta de granito, de excelente calidad, moderna y bien distribuida con modernos espacios. <br><br>
Situada en el Fraccionamiento Mediterraneo siendo uno de los complejos más exclusivos del puerto de Mazatlán, cerca de la zona de mayor plusvalía y exclusividad, a solo unos pasos de el Shopping Mall más grande y lujoso GALERIAS MAZATLÁN, frente al HOSPITAL MARINA MAZATLÁN, y a unos metros de la zona de mayor crecimiento, MUY CERCA DEL MAR Y HERMOSAS PLAYAS DE MAZATLÁN, tan solo a 5 minutos de la Marina y playas, universidad Tec Milenio, bancos, escuelas, Wal Mart, plazas comerciales y muchas ventajas, gozando siempre de exclusividad por ser un complejo cerrado con acceso controlado muy tranquilo, abundante naturaleza y tranquilidad. <br><br>
Esta lujosa casa, es única debido a que cuenta con un terreno excedente donde se construyó un patio lateral, para tener una área de esparcimiento, incluso colocar tu asador o algún mueble de jardín, situada en un callejón interior del complejo 100% familiar y tranquilo, rodeado de un entorno equilibrado en colores y casas estilo Mediterraneo, muy buenos vecinos y hermosos entornos en el Fraccionamiento.  <br><br>
Mediterraneo es un complejo de lujo, cuenta con 6 albercas a elección de sus habitantes, club de eventos sociales, cancha de tenis, y hermosos paisajes y muchas amenidades más.`,
features: [ "Cemento",
            "Remodelado",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Autobús cercano",
            "Cerca de Zona Turística",
            "Gimnasio cercano",
            "Cerca de Campo de Golf",
            "Hospital cercano",
            "Cerca de Avenida",
            "Cerca de Parque",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Zona Concurrida",
            "Zona Tranquila",
            "Por el Mar",
            "Cerca de Centro Médico",
            "Norte",
            "Aire Acondicionado",
            "Electricidad",
            "Gas",
            "Agua Corriente",
            "Tanque de Agua",
            "Vista a la Ciudad",
            "Terraza",
            "Mascotas permitidas",
            "Lote en Esquina",
            "Sin Amueblar",
            "Baño en Habitación",
            "Mediterráneo"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/36.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/37.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/38.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/39.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/40.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/41.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/42.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/43.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2208/44.jpg")
],
city: "Mazatlan, Sinaloa"},
*/
{id: "GT-1079-2119",
relevant: false, //only six properties should have this turned on
available: true,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
price: 4250000, // MXN $ 4,250,000 US$ 209,916
zone: "SABALO COUNTRY CLUB",
stories: 2,
bedrooms: 3,
bathroomsComplete: 2,
bathroomsHalf: 1,
parkingSpaces: 2,
constructionArea: 215 , //in square meters
landArea: 196, //in square meters
description: `Sabalo Country Club Mazatlán, mejor conocido como el lugar  #1, preferido para vivir en Mazatlán, se encuentra a solo unas cuadras de Marina Mazatlán, muy cerca de Galerias, siendo el complejo de centros comerciales más grandes y modernos de este puerto,  a solo unas cuadras de los campos de golf más famosos de Mazatlán, restaurantes y hoteles están ubicados a no más de 400 mts. No te lo pudes perder!! Ven a visitar esta grandiosa zona y descubra este hermoso y exclusivo fraccionamiento, para adquirir la casa de sus sueños. <br><br>
Si deseas vivir en Mazatlán debes saber que Sábalo Country es parte de la Zona Dorada ya que que se encuentra a solo unas cuadras de la playa, además cuenta con fácil acceso a la ruta de servicio público, restaurantes, tiendas, parques, escuelas y servicios de guardería. <br><br>
Esta casa cuenta con 3 dormitorios y 2.5 baños. <br><br>
El dormitorio principal tiene balcón y un gran baño con jacuzzi privado y un gran sistema de closets empotrados que se adaptarán a tus necesidades. <br><br>
Los otros 2 dormitorios en el piso superior, comparten un bonito y amplio baño que también cuenta con puerta corrediza de vidrio para ducha y nuevos azulejos y tocador modernos. <br><br>
El piso principal tiene estacionamiento seguro para dos autos, puerta de garaje automática y un hermoso corredor. Para los amantes de la cocina, esta casa cuenta con una amplia cocina completa con acceso a patio trasero y área de lavado. No pierdas el tiempo. Ponte en contacto conmigo para concertar una visita ahora.`,
features: [ "Cemento",
            "Muy céntrico",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Autobús cercano",
            "Cerca de un parque con juegos infantiles",
            "Cerca de Zona Turística",
            "Gimnasio cercano",
            "Cerca de Campo de Golf",
            "Hospital cercano",
            "Cerca de Avenida",
            "Cerca de Parque",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Zona Concurrida",
            "Zona Tranquila",
            "Por el Mar",
            "Cerca de Centro Médico",
            "Este",
            "Cable",
            "Jacuzzi",
            "Seguridad - Alarma",
            "Bomba Hidráulica",
            "Balcón",
            "Garaje con Puerta Automática",
            "Espacio para juegos",
            "Terraza",
            "Cancha de Tenis",
            "Puerta Delantera de Acero",
            "Desayunador",
            "Sin Amueblar",
            "Cuarto de lavado",
            "Baño en Habitación",
            "Mediterráneo"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r36.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r37.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r38.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r39.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r40.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r41.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r42.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r43.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r44.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "GT-1079-2115",
relevant: false, //only six properties should have this turned on
available: false,
transactionType: TransactionType.ForRent,
propertyType: PropertyType.House,
price: 17000 , // MXN $ 17,000 US$ 840
zone: "ALTABRISA Residencial",
stories: 2,
floor: 1,
bedrooms: 3,
bathroomsComplete: 3,
parkingSpaces: 2,
constructionArea: 204, //in square meters
landArea: 160, //in square meters
description: `Casa nuevecita!! Estrénela!! Quieres vivir de lujo muy cerca de la playa y en la zona de mejor proyección, zona muy tranquila justo frente al Hotel Pueblo Bonitop Emerald Bay se encuentra este fraccionamiento con casas hermosas, nuevas, lo que asegura un entorno de lujo, buenos vecinos y sobre todo ambiente familiar. Altabrisa Residencial  le ofrece a sus residentes Gimnasio, Alberca, acceso exclusivo y vigilancia 24 horas, además la casa sta en una excelente ubicación, a solo unos pasos de la casa Club y de la entrada del Fraccionamiento. <br><br>
Casa consta de 3 recamaras, una de ellas en planta baja, closets, 3 baños completos, cocina integral, barra de granito, cuarto de lavado en planta alta, cochera para dos autos, patio con jardín, aires acondicionados en toda la casa. <br><br>
**** SIN AMUEBLAR <br><br>
DESCRIPCION DE ESPACIOS <br><br>
PLANTA BAJA:<br><br>
<ul>

<li>Cochera  Para Dos Autos</li>
<li>Recibidor </li>
<li>1 Recámara en planta baja con closet vestidor</li>
<li>1 Baño Completo, cancel de cristal templado 9mm</li>
<li>Cocina Integral con Barra de Granito</li>
<li>Espacio para Sala</li>
<li>Espacio para Comedor</li>
<li>Patio trasro con jardín </li>

</ul>

<br><br>
PLANTA ALTA: <br><br>
<ul>

<li>1 Recámara con closet y baño dentro de la recámara </li>
<li>1 Recámara con vestidor y baño dentro de la recámara </li>
<li>Sala de TV</li>
<li>Terraza</li>
<li>Cuarto de Lavado</li>

</ul>
S U P E R F I C I E
<ul>
<li>TERRENO: 160 m<sup>2</sup> ( 8 x 20 Mts.)</li>
<li>CONSTRUCCIÓN: 204m<sup>2</sup></li>
</ul>
`,
features: [ "Cemento",
            "A estrenar",
            "Ubicación accesible",
            "Accesible al Tráfico",
            "Cerca de la Playa",
            "Cerca de un parque con juegos infantiles",
            "Cerca de Zona Turística",
            "Gimnasio cercano",
            "Cerca de Avenida",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Zona Concurrida",
            "Zona Tranquila",
            "Por el Mar",
            "Sureste",
            "Internet de Alta Velocidad",
            "Aire Acondicionado",
            "Gimnasio",
            "Gas",
            "Vista a la Ciudad",
            "Balcón",
            "Espacio para juegos",
            "Terraza",
            "Mascotas permitidas",
            "Alberca",
            "Sin Amueblar",
            "Barra de Desayuno",
            "Recámara Planta Baja",
            "Moderno"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r36.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r37.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r38.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r39.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r40.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r41.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r42.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r43.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r44.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2115/r45.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "GT-1079-2024",
relevant: false, //only six properties should have this turned on
available: false,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.Department,
price: 1700000 , // MXN $ 1,700,000 US$ 83,966
zone: "Azul Pacifico",
stories: 1,
floor: 3,
bedrooms: 2,
bathroomsComplete: 2,
parkingSpaces: 1,
constructionArea: 65.22, //in square meters
landArea: 65.22, //in square meters
description: `Este bonito y pequeño departamento totalmente amueblado, consta de una sala, comedor, cocina completamente equipada, cuarto de lavado, dos recámaras y dos baños completos, se ubica en el tercer piso, dispone de mucha iluminación, ideal para tener un espacio seguro y discreto cerca de la playa, edificio preferentemente situado frente al jardín y alberca. <br><br>
El Fraccionamiento Azul Pacífico se encuentra en Mazatlán sinaloa México, 18 km de la famosa Plazuela Machado ya 21 km del faro natural mas alto del mundo, muy cerca del mar, se ubica en un condominio que se encuentra ubicado en la zona denominada Nuevo Mazatlán, frente al Hotel Emerald Bay, a solo 5 minutos de la playa. Se encuentra a 1,3 km de la playa Cerritos / Brujas y cuenta con piscina al aire libre y aparcamiento privado gratuito. <br><br>
Ambiente familiar, seguro y de mucha tranquilidad Azul Pacífico, cuenta con acceso controlado, seguridad 24/7 y cuatro áreas recreativas verdes, cada una con su alberca donde usted puede disfrutar con sus seres significativos. <br><br>
Este condominio se vende completamente amueblado, con una distribución bastante buena en sus espacios muy bien aprovechados.`,
features: [ "Cerca de Zona Turística",
            "Cerca de Campo de Golf",
            "Cerca de Autopista",
            "Cerca de Avenida",
            "Cerca de Parque",
            "Transporte Público Cercano",
            "Tiendas cercanas",
            "Zona Concurrida",
            "Zona Tranquila",
            "Por el Mar",
            "Seguridad - Vigilancia",
            "Agua Corriente",
            "Amueblado",
            "Moderno"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2024/r30.jpg")
],
city: "Mazatlan, Sinaloa"},

{id: "GT-1079-1954",
relevant: true, //only six properties should have this turned on
available: false,
transactionType: TransactionType.ForSale,
propertyType: PropertyType.House,
priceUsd: 250000,
zone: "Villas Puerto Iguana",
stories: 3,
bedrooms: 3,
bathroomsComplete: 3,
bathroomsHalf: 1,
parkingSpaces: 1,
constructionArea: 185.93, //in square meters
landArea: 199, //in square meters
description: `¡Una vista espectacular! ¡Casa de tres niveles con vista panorámica, te dejará sin aliento la vista de la sala, comedor, así como cada recámara, magnifica ubicación para disfrutar del mar y sus inigualables atardeceres que solo en Mazatlán se viven, no pierdas la oportunidad de conocer esta grandiosa oportunidad! <br><br>
Desde tú recamará tienes vista panorámica a un hermoso canal de navegación, rodeado de naturaleza, hermosos paisajes, atardeceres inigualables, y si tienes yate o embarcación, puedes atracarlo en la parte trasera de esta hermosa propiedad, disfruta de vivir rodeado de naturaleza, paz y tranquilidad,  y de mirar el mar desde que abres tus ojos por la mañana. <br><br>
Planta baja <br><br>
Esta hermosa vivienda en su planta baja, se encuentra su acceso principal, medio baño, pasillo y cocina integral, barra de granito, cuarto de lavado y alacena, y acceso de escalera hacia el primer piso  <br><br>
Comedor, estancia, barra y terraza posterior, con una vista al canal de navegación del desarrollo Marina Mazatlán, puerto y paseo vial, jardín exterior posterior. <br><br>
Planta alta <br><br>
Primer piso, escalera, dos recamarás, con baño completo, y balcón, vista panorámica y vista frontal al área jardinada. <br><br>
Planta alta <br><br>
Segundo nivel de escaleras, recamará principal con baño doble lavamanos, y terraza exterior roof garden vista al mar. <br><br>
Áreas comunes <br><br>
Alberca de gran tamaño, es un conjunto de tan solo 38 villas, que gozan de una hermosa vista al canal de navegación, club de Golf Marina Mazatlán y ubicado en una zona privada, alejada del tráfico y el bullicio, a solo 5 minutos de centros comerciales, plaza Galerías, cines, restaurantes, Marina Mazatlán, Marina Náutica, Playa, Gasolineras, en la mejor ubicación del Boulevard Marina Mazatlán, Conjunto Puerto Iguana es la mejor opción de vista y plusvalía, ubicación, privacidad y seguridad.`,
features: [ "Cemento",
            "Portero",
            "En perímetro de bosque",
            "Ubicación accesible",
            "Frente al Lago",
            "Accesible al Tráfico",
            "Densidad de Tráfico",
            "Cerca de la Playa",
            "Autobús cercano",
            "Cerca de un parque con juegos infantiles",
            "Cerca de Zona Turística",
            "Gimnasio cercano",
            "Cerca de Campo de Golf",
            "Cerca de Autopista",
            "Hospital cercano",
            "Cerca de Avenida",
            "Cerca de Parque",
            "Transporte Público Cercano",
            "Colegio cercano",
            "Tiendas cercanas",
            "Zona Concurrida",
            "Zona Tranquila",
            "Por el Mar",
            "Cerca de Centro Médico",
            "Noroeste",
            "Internet de Alta Velocidad",
            "Aire Acondicionado",
            "Electricidad",
            "Gas",
            "Seguridad - Vigilancia",
            "Agua Corriente",
            "Vista al Mar",
            "Vista a Playa",
            "Vista a Marina",
            "Vista al Lago",
            "Vista panorámica",
            "Vista Parcial a Campo de Golf",
            "Vista Parcial a Marina",
            "Vista a Área Abierta",
            "Balcón",
            "Jardín",
            "Espacio para juegos",
            "Terraza",
            "Mascotas permitidas",
            "Alberca",
            "Desayunador",
            "Baño de servicio",
            "Sin Amueblar",
            "Cuarto de lavado",
            "Baño en Habitación",
            "Armario Empotrado",
            "Mediterráneo"
 ],
images: [ new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r1.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r2.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r3.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r4.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r5.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r6.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r7.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r8.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r9.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r10.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r11.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r12.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r13.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r14.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r15.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r16.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r17.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r18.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r19.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r20.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r21.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r22.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r23.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r24.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r25.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r26.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r27.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r28.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r29.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r30.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r31.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r32.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r33.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r34.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r35.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r36.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r37.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r38.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r39.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r40.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r41.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r42.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r43.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r44.jpg"),
          new Image("https://realestateimages.blob.core.windows.net/realestateimages/1079-2119/r45.jpg")
],
city: "Mazatlan, Sinaloa"}

//#endregion
]

;//

  constructor(protected http: HttpClient) { }

  getProperty(id: string){
    return this.properties.find(prop => prop.id === id);
  }

  sendEmail(emailParam: string, subjectParam: string, bodyParam:string){
    return this.http.post(this.baseUrl + 'Feedback' , {
      email:emailParam,
      subject: subjectParam,
      body: bodyParam
    });
  }

  getAll(){
    return this.properties;
  }

  getCurrency(){
    return this.http.get(this.baseUrl + 'Forex' );
  }

  setExchangeRate(xr:number){
    this.exchangeRate = xr;
    this.properties = this.properties.map(item => {
      if(item.price == null){
        item.price = item.priceUsd * this.exchangeRate;
      }
      else if(item.priceUsd == null) {
        item.priceUsd = item.price / this.exchangeRate;
      }
      return item;
    });
  }
}
