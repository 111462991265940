import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SingleFilter } from '../filter/filter.model';
import { FilterService } from '../filter/filter.service';
import { TransactionTypeArray, PropertyTypeArray, Property } from '../Shared/Property.model';
import { PropertyService } from '../Shared/property.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  transactions = TransactionTypeArray
  propTypes = PropertyTypeArray;

  propertyType:string;
  transactionType:string;

  properties: Property[];

  constructor(private propService: PropertyService,
    private filterService: FilterService,
    private router: Router) {
    this.properties = this.propService.getAll().filter(item=>item.relevant);
    this.propertyType = "";
    this.transactionType = "";
    //debugger;
  }

  ngOnInit(): void {
  }
  
  filterProps(){
    var filtersAdded = false;
    this.filterService.clearFilter();
    if(this.propertyType){
      var filter: SingleFilter = new SingleFilter();
      filter.attributeName = "propertyType";
      filter.attributeValue = this.propertyType;
      this.filterService.addFilter(filter);
      filtersAdded = true;
    }
    if(this.transactionType){
      var filter2: SingleFilter = new SingleFilter();
      filter2.attributeName = "transactionType";
      filter2.attributeValue = this.transactionType;
      this.filterService.addFilter(filter2);
      filtersAdded = true;
    }
    if(filtersAdded){
      this.filterService.update();
    }
    this.router.navigate(['/filter']);
  }

  updateFilter(value, property:string) {
    this[property] = value;
  }
}
