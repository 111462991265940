<!-- Cover -->

<div class="cover parallax">
  <div class="menu animate-in">
    <span>¿Qué buscas?</span>
    <div class="forms-container">
      <mat-form-field class="searcher" appearance="fill">
        <mat-label>Tipo de Transaccion</mat-label>
        <mat-select (selectionChange)="updateFilter($event.value, 'transactionType');">
          <mat-option *ngFor="let type of transactions" [value]="type.value">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="searcher" appearance="fill">
        <mat-label>Tipo de propiedad</mat-label>
        <mat-select (selectionChange)="updateFilter($event.value, 'propertyType');">
          <mat-option *ngFor="let type of propTypes" [value]="type.value">
            {{type.value}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <button mat-icon-button class="search-icon" (click)="filterProps()">
        <mat-icon class="material-icons-round search-icon">search</mat-icon>
      </button>

      <button mat-raised-button color="primary" class="search-button" (click)="filterProps()">
        Búsqueda
      </button>
    </div>
  </div>
</div>

<div
  style="font-family:  'Gill Sans MT'; font-style: italic; font-size: 2.9em; letter-spacing: 7px; line-height: 1; padding: 30px; text-align: center;  background: #ffffff;">
  <p>propiedades destacadas</p>
</div>

<!-- Cards -->
<div class="cards-container">
  <app-card *ngFor="let prop of properties" [property]="prop"></app-card>
</div>