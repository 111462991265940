import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Filter, FilterViewModel, SingleFilter } from '../filter/filter.model';
import { FilterService } from '../filter/filter.service';
@Component({
  selector: 'app-filter-popup',
  templateUrl: './filter-popup.component.html',
  styleUrls: ['./filter-popup.component.scss']
})
export class FilterPopupComponent implements OnInit {

  transactionExpanded: boolean;
  propertyTypeExpanded: boolean;
  priceExpanded: boolean;
  bedroomsExpanded: boolean;
  bathroomsExpanded: boolean;

  maxPriceFormControl: FormControl;
  minPriceFormControl: FormControl;

  rentaFormControl: FormControl;
  ventaFormControl: FormControl;
  terrenoFormControl: FormControl;
  terreno1FormControl: FormControl;

  filtersSubscription: Subscription;

  filtersViewModel: FilterViewModel;

  bedrooms: string[] = ['1+ Recámaras', '2+ Recámaras', '3+ Recámaras', '4+ Recámaras', '5+ Recámaras'];
  bathrooms: string[] = ['1+ Baños', '2+ Baños', '3+ Baños', '4+ Baños', '5+ Baños'];

  constructor(private filterService: FilterService,
    public dialogRef: MatDialogRef<FilterPopupComponent>,) {
    this.filtersSubscription = this.filterService.filters$.subscribe((globalFilters) => {
      this.filtersViewModel = new FilterViewModel();
      globalFilters.forEach((filter: Filter) => {
        var filterValues = filter.attributeValues;
        filterValues.map((value: String) => {
          var tempValue = value.replace(" ", "");
          while (tempValue.includes(" ")) {
            tempValue = tempValue.replace(" ", "");
          }
          if (filter.attributeName === "propertyType" || filter.attributeName === "transactionType") {
            this.filtersViewModel[filter.attributeName + "_" + tempValue] = true;
          }
          if (filter.attributeName === "Bedrooms") {
            this.filtersViewModel.selectedBedroom = value + "+ Recámaras";
          }
          if (filter.attributeName === "Bathrooms") {
            this.filtersViewModel.selectedBedroom = value + "+ Baños";
          }
          if (filter.attributeName === "Minimum") {
            this.filtersViewModel.minimum = Number(value);
          }
          if (filter.attributeName === "Maximum") {
            this.filtersViewModel.maximum = Number(value);
          }
        });
      });
      if (this.filtersViewModel.minimum != 0)
        this.minPriceFormControl = new FormControl(this.filtersViewModel.minimum, Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));
      else
        this.minPriceFormControl = new FormControl('', Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));

      if (this.filtersViewModel.maximum != 0)
        this.maxPriceFormControl = new FormControl(this.filtersViewModel.maximum, Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));
      else
        this.maxPriceFormControl = new FormControl('', Validators.pattern("^([0-9]{1,3},([0-9]{3},)*[0-9]{3}|[0-9]+)(.[0-9]{1,2})?$"));
    });
  }

  ngOnInit(): void {
  }

  assignPrice(value, attribute) {
    this.filtersViewModel[attribute] = value;
  }

  onNoClick() {
    this.filtersSubscription.unsubscribe();
    this.dialogRef.close();
  }

  clearPriceValue(attribute) {
    if (attribute === "minimum") {
      this.minPriceFormControl.setValue(0);
      this.filtersViewModel.minimum = 0;
    }
    if (attribute === "maximum") {
      this.maxPriceFormControl.setValue(0);
      this.filtersViewModel.maximum = 0;
    }
  }
}
