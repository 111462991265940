<h1 mat-dialog-title>Filtros</h1>
<mat-dialog-content class="filters-popup-container">
    <mat-accordion multi class="filter-accordion">
        <mat-expansion-panel [expanded]="transactionExpanded" class="filter-header">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Tipo de Transacción
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="transType-container">
                <mat-checkbox [(ngModel)]="filtersViewModel.transactionType_Renta" [color]="'primary'">
                    Renta</mat-checkbox>

                <mat-checkbox [(ngModel)]="filtersViewModel.transactionType_Venta" [color]="'primary'">
                    Venta</mat-checkbox>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="propertyTypeExpanded" class="filter-header">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Tipo de Transacción
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="propType-container">
                <mat-checkbox [(ngModel)]="filtersViewModel.propertyType_Casa" [color]="'primary'">
                    Casa</mat-checkbox>

                <mat-checkbox [(ngModel)]="filtersViewModel.propertyType_Departamento" [color]="'primary'">
                    Departamento</mat-checkbox>

                <!--mat-checkbox [(ngModel)]="filtersViewModel.propertyType_BodegaComercial" [color]="'primary'">
                    Bodega Comercial</mat-checkbox-->

                <mat-checkbox [(ngModel)]="filtersViewModel.propertyType_Edificio" [color]="'primary'">
                    Edificio</mat-checkbox>

                <mat-checkbox [(ngModel)]="filtersViewModel.propertyType_LocalComercial" [color]="'primary'">
                    Local Comercial</mat-checkbox>

                <!--mat-checkbox [(ngModel)]="filtersViewModel.propertyType_LocalenCentroComercial" [color]="'primary'">
                    Local en Centro Comercial</mat-checkbox-->

                <mat-checkbox [(ngModel)]="filtersViewModel.propertyType_Oficina" [color]="'primary'">
                    Oficina</mat-checkbox>

                <mat-checkbox [(ngModel)]="filtersViewModel.propertyType_Terreno" [color]="'primary'">
                    Terreno</mat-checkbox>

                <!--mat-checkbox [(ngModel)]="filtersViewModel.propertyType_TerrenoComercial" [color]="'primary'">
                    Terreno Comercial</mat-checkbox-->
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="priceExpanded" class="filter-header price-filter">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Precio
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="transType-container">
                <mat-form-field class="price-input">
                    <mat-label>Mínimo</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput type="text" [formControl]="minPriceFormControl" (keyup)="assignPrice($event.srcElement.value, 'minimum')">
                    <button mat-button *ngIf="filtersViewModel.minimum!==0" matSuffix mat-icon-button
                        (click)="clearPriceValue('maximum')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="minPriceFormControl.hasError('pattern')">
                        Cantidad inválida
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="price-input">
                    <mat-label>Máximo</mat-label>
                    <span matPrefix>$ &nbsp;</span>
                    <input matInput type="text" [formControl]="maxPriceFormControl" (keyup)="assignPrice($event.srcElement.value, 'maximum')">
                    <button mat-button *ngIf="filtersViewModel.maximum!==0" matSuffix mat-icon-button
                        (click)="clearPriceValue('maximum')">
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-error *ngIf="maxPriceFormControl.hasError('pattern')">
                        Cantidad inválida
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="bedroomsExpanded" class="filter-header">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Recámaras
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="propType-container">
                <mat-radio-group aria-labelledby="example-radio-group-label"
                    [(ngModel)]="filtersViewModel.selectedBedroom" class="bathrooms-radios">
                    <mat-radio-button class="filter-radio-button" *ngFor="let bedroom of bedrooms" [value]="bedroom"
                        [color]="'primary'">
                        {{bedroom}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="bathroomsExpanded" class="filter-header">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Baños
                </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="propType-container">
                <mat-radio-group aria-labelledby="example-radio-group-label" class="bathrooms-radios"
                    [(ngModel)]="filtersViewModel.selectedBathroom">
                    <mat-radio-button class="filter-radio-button" *ngFor="let bathroom of bathrooms" [value]="bathroom"
                        [color]="'primary'">
                        {{bathroom}}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</mat-dialog-content>
<div mat-dialog-actions class="popup-buttons">
    <button mat-raised-button (click)="onNoClick()" color>Cancelar</button>
    <button mat-raised-button [mat-dialog-close]="filtersViewModel" cdkFocusInitial color="primary">Aplicar</button>
</div>