<div class="details-container" (window:resize)="onResize($event)">
  <!-- Title -->
  <div class="title-container">
    <div class="title">{{property.propertyType}} en {{property.zone}}, {{property.city}}</div>
    <div class="trans-contact-container">
      <div class="trans-type-container">
        <div class="triangle-left"></div>
        <h2 class="trans-type">{{property.transactionType}}</h2>
        <div class="triangle-right"></div>
      </div>
      <div class="cellphone">
        <span>¡Me interesa!</span>
        <a class="icon-container phone-icon" href="tel:6691580971">
          <mat-icon class="disable-text-select" style="font-size:24px;color: white;">
            phone</mat-icon>
        </a>
        <a class="icon-container whatsapp-icon"
          href="https://wa.me/5216691580971?text=Hola%21+Estoy+Interesado+en+la+propiedad+www.lupitamarrero.com%2Fdetail%2F{{property.id}}">
          <fa-icon [icon]="faWhatsapp" style="font-size:24px;color: white;"></fa-icon>
        </a>
        <a class="icon-container email-icon" (click)="openDialog()">
          <mat-icon class="disable-text-select" style="font-size:24px;color: white;">mail_outline</mat-icon>
        </a>
      </div>
    </div>
  </div>

  <!-- Carousel -->
  <div class="carousel-div">
    <carousel [cellWidth]="'100%'" [arrowsOutside]="arrowsOutside" [dots]="true" [loop]="true" [lightDOM]="true"
      [borderRadius]="20" id="carousel" style="height: unset;">
      <div class="carousel-cell" *ngIf="property.video">
        <iframe class="video" *ngIf="property.video" [src]="safeURL" frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;">
        </iframe>
      </div>
      <div class="carousel-cell" *ngFor="let image of property.images">
        <img [src]="image.path">
      </div>
    </carousel>
  </div>

  <!-- Properties -->
  <div class="feature-container">
    <div class="feature-item" *ngIf="property.price || property.priceUsd" style="justify-content: center;">
      <span *ngIf="property.price" style="font-size: 20px;">{{ property.price | currency }} MXN*</span>
      <span *ngIf="property.priceUsd" style="font-size: 20px;">{{ property.priceUsd | currency }} USD*</span>
    </div>
    <div class="feature-item" *ngIf="property.bedrooms">
      <div class="icon-attribute">
        <mat-icon class="disable-text-select">bed</mat-icon><span>&nbsp;&nbsp;&nbsp;{{property.bedrooms}}</span>
      </div>
      Recámaras
    </div>
    <div class="feature-item" *ngIf="property.bathroomsComplete">
      <div class="icon-attribute">
        <fa-icon [icon]="faShower" style="font-size:30px;color: #212529;"></fa-icon>
        <span>&nbsp;&nbsp;&nbsp;{{property.bathroomsComplete}}
          <span *ngIf="property.bathroomsHalf">&nbsp;+ {{property.bathroomsHalf}}/2</span></span>
      </div>
      Baños
    </div>
    <div class="feature-item" *ngIf="property.landArea">
      <div class="icon-attribute">
        <mat-icon class="disable-text-select">square_foot</mat-icon><span>&nbsp;{{property.landArea}}
          m<sup>2</sup></span>
      </div>
      Superficie
    </div>
    <div class="feature-item" *ngIf="property.constructionArea">
      <div class="icon-attribute">
        <mat-icon class="disable-text-select">square_foot</mat-icon><span>&nbsp;{{property.constructionArea}}
          m<sup>2</sup></span>
      </div>
      Construcción
    </div>
    <div class="feature-item" *ngIf="property.parkingSpaces">
      <div class="icon-attribute">
        <mat-icon class="disable-text-select">directions_car</mat-icon>
        <span>&nbsp;&nbsp;{{property.parkingSpaces}}</span>
      </div>
      Estacionamiento
    </div>
  </div>

  <!-- Description -->
  <div class="features">
    <div class="features-title">
      Descripción
    </div>
    <hr style="margin-bottom: calc(1rem + 10px);">
    <div id="propBody" class="details"></div>
  </div>


  <!-- Features -->
  <div class="features">
    <div class="features-title">
      Características
    </div>
    <hr style="margin-bottom: calc(1rem + 10px);">
    <ul>
      <li *ngFor="let feature of property.features">
        {{feature}}
      </li>
    </ul>
  </div>



</div>