import { Component, Input, AfterViewInit } from '@angular/core';
import { Property, PropertyType, TransactionType } from '../Shared/Property.model';
import { faShower } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements AfterViewInit {

  faShower = faShower;

  @Input() property?: Property;

  propType = PropertyType;
  transType = TransactionType;
  constructor() {}

  ngAfterViewInit(): void {
    if(this.property.unit){
      document.getElementById("unit" + this.property.id).innerHTML = "&nbsp;por " + this.property.unit;
    }
  }

}
