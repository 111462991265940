import { Component } from '@angular/core';
import { PropertyService } from './Shared/property.service';
import { AngularFaviconService } from 'angular-favicon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Lupita Marrero';

  constructor(private propService: PropertyService,
    private ngxFavicon: AngularFaviconService) {

    this.propService.getCurrency().subscribe((res: any) => {
      this.propService.setExchangeRate(res);
    });

    this.ngxFavicon.setFavicon("../assets/IconLogo-Black.svg", "../assets/IconLogo-White.svg");
  }

}


