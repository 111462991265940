<div class="footer-container">
    <section class="info-up">
        <div class="welcome">
            <div class="logo-container">
                <img class="logo" src="./assets/IconLogo-Grey.svg">
                <img class="letters" src="./assets/LogoFont-Grey.svg">
            </div>
            <span>Empresa líder en el mercado inmobiliario en Mazatlán. Nos encantaría ayudarte a encontrar la mejor
                opción en Propiedades en Mazatlán.</span>
            <span>&nbsp;</span>
            <span>*Los precios mostrados aquí son solo como referencia y pueden cambiar sin
                previo aviso</span>
        </div>

        <div class="contact">
            <div><img class="logo2" src="./assets/IconLogo-Grey.svg"><span>&nbsp;</span></div>
            <span>Contáctanos</span>
            <span>Av. Reforma 2007-BL-F1, Laguna Plaza</span>
            <span>Fraccionamiento Flamingos C.P. 82149</span>
            <span>Mazatlan, Sinaloa</span>
            <div class="cellphone">
                <span>(+52) 669-158-0971</span>
                <a class="icon-container" href="tel:+526691580971">
                    <mat-icon class="disable-text-select" style="font-size:24px;color: #5f5c5c;">
                        phone</mat-icon>
                </a>
                <a class="icon-container" href="https://wa.me/5216691580971">
                    <fa-icon [icon]="faWhatsapp" style="font-size:24px;color: #5f5c5c;"></fa-icon>
                </a>
                <a class="icon-container" (click)="openDialog()">
                    <mat-icon class="disable-text-select" style="font-size:24px;color: #5f5c5c;">
                        mail_outline</mat-icon>
                </a>
            </div>
        </div>
    </section>

    <section class="info-down">
        <div class="rights">
            <span>Todos los derechos reservados,</span>
            <span>Copyright &copy; 2021 WWW.LUPITAMARRERO.COM</span>
        </div>

        <div class="power">
            <span>Powered by <a href="https://www.moneta.studio/home">Moneta Studio</a></span>
        </div>
    </section>
</div>