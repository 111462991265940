import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PropertyService } from "../Shared/property.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  formModel: FormGroup;
  email: string;
  subject: string;
  body: string;

  constructor(private fb: FormBuilder,
    private _service: PropertyService,
    private _snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string) {

    this.formModel = this.fb.group({
      email: [this.email, [Validators.required, Validators.email]],
      subject: [this.subject, Validators.required],
      body: [this.body, Validators.required]
    });

  }

  /*
    openSnackBar(message: string, action: string) {
      this._snackBar.open(message, action);
    }
  */
  ngOnInit(): void {
  }

  updateForm(value: any, property: string) {
    this[property] = value;
  }

  enviar() {
    if(this.data){
      this.body = this.body + "\r\nPropiedad: www.lupitamarrero.com/detail/" + this.data;
    }
    this._service.sendEmail(this.email, this.subject, this.body).subscribe(
      (res: any) => {
        this.dialogRef.close();
        this._snackBar.open("Correo enviado", "Cerrar");
      },
      err => {
        this._snackBar.open("Se presentó un error al enviar el correo", "Cerrar");
        console.log(err);
      },
      () => { });
  }

}
