<div class="feedback-container">

    <h1 mat-dialog-title class="black-text">Enviame un correo</h1>
    <form [formGroup]="formModel">
        <mat-form-field>
            <mat-label>Escribe tu correo</mat-label>

            <input matInput [value]="email" formControlName="email" class="input-style"
                (keyup)="updateForm($event.srcElement.value, 'email')">

            <button mat-button *ngIf="email" matSuffix mat-icon-button aria-label="Clear" class="input-style"
                (click)="email=''">
                <mat-icon>close</mat-icon>
            </button>

            <mat-error *ngIf="formModel.get('email').touched && formModel.get('email').hasError('required')">
                Este campo es requerido.
            </mat-error>

            <mat-error *ngIf="formModel.get('email').touched && formModel.get('email').hasError('email')">
                Direccion inválida de correo.
            </mat-error>

        </mat-form-field>

        <mat-form-field>
            <mat-label>Asunto</mat-label>

            <input matInput [value]="subject" formControlName="subject" class="input-style"
                (keyup)="updateForm($event.srcElement.value, 'subject')">

            <button mat-button *ngIf="subject" matSuffix mat-icon-button aria-label="Clear" class="input-style"
                (click)="subject=''">
                <mat-icon>close</mat-icon>
            </button>

            <mat-error *ngIf="formModel.get('email').touched && formModel.get('subject').hasError('required')">
                Este campo es requerido.
            </mat-error>
        </mat-form-field>

        <mat-form-field class="textaf">
            <mat-label style="padding-left: 10px; padding-top: 10px;">Incluye toda la información posible...</mat-label>
            <textarea class="texta" matInput [value]="body" formControlName="body"
                (keyup)="updateForm($event.srcElement.value, 'body')"></textarea>
            <mat-error *ngIf="formModel.get('email').touched && formModel.get('body').hasError('required')">
                Este campo es requerido.
            </mat-error>
        </mat-form-field>

    </form>
    <div mat-dialog-actions class="feedback-buttons">
        <a mat-stroked-button mat-dialog-close class="cancel-button">Cancelar</a>
        <button [disabled]="!formModel.valid" (click)="enviar()" mat-raised-button color="primary">Enviar</button>
    </div>
</div>