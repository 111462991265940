<mat-card class="example-card" *ngIf="property" [routerLink]="['/','detail',property.id]">
    <mat-card-header>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Casa'" class="disable-text-select">house</mat-icon>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Departamento'" class="disable-text-select">apartment
        </mat-icon>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Bodega Comercial'" class="disable-text-select">gite
        </mat-icon>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Edificio'" class="disable-text-select">business
        </mat-icon>
        <!--mat-icon mat-card-avatar *ngIf="property.propertyType === PropertyType.Local" class="disable-text-select"></mat-icon-->
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Local Comercial'" class="disable-text-select">
            storefront</mat-icon>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Local en Centro Comercial'"
            class="disable-text-select">local_mall</mat-icon>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Oficina'" class="disable-text-select">
            business_center</mat-icon>
        <!--mat-icon mat-card-avatar *ngIf="property.propertyType === PropertyType.Other" class="disable-text-select">home</mat-icon-->
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Terreno'" class="disable-text-select">pin_drop
        </mat-icon>
        <mat-icon mat-card-avatar *ngIf="property.propertyType === 'Terreno comercial'" class="disable-text-select">
            pin_drop</mat-icon>
        <mat-card-title class="card-text">{{property.propertyType}} en {{property.zone}}</mat-card-title>
        <mat-card-subtitle *ngIf="property.price">
             <span style="font-family:  'Gill Sans MT';" *ngIf="property.price">MXN {{property.price | currency}}*</span> &nbsp;
             <span style="font-family:  'Gill Sans MT';" *ngIf="property.priceUsd">US {{property.priceUsd | currency}}*</span>
            <span *ngIf="property.unit" [id]="'unit' + property.id"></span>
        </mat-card-subtitle>
    </mat-card-header>
    <img mat-card-image [src]="property.images[0].path">
    <mat-card-content>
        <div class="trans-type-container">
            <h2 style="font-family:  'Gill Sans MT';" class="title">{{property.transactionType}}</h2>
            <div class="triangle"></div>
        </div>
        <div class="card-icon">
            <div class="icon-attribute" *ngIf="property.bedrooms">
                <mat-icon class="disable-text-select">bed</mat-icon><span>{{property.bedrooms}}</span>
            </div>
            <div class="icon-attribute" *ngIf="property.bathroomsComplete">
                <fa-icon [icon]="faShower" style="font-size:30px;color: #212529;"></fa-icon>
                <span>{{property.bathroomsComplete}}<span *ngIf="property.bathroomsHalf">&nbsp;+ {{property.bathroomsHalf}}/2</span></span>
            </div>
            <div class="icon-attribute" *ngIf="property.landArea">
                <mat-icon class="disable-text-select">square_foot</mat-icon><span>{{property.landArea}}
                    m<sup>2</sup></span>
            </div>
            <div class="icon-attribute" *ngIf="property.parkingSpaces">
                <mat-icon class="disable-text-select">directions_car</mat-icon><span>{{property.parkingSpaces}}</span>
            </div>
        </div>
    </mat-card-content>
</mat-card>